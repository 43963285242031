import React, { useEffect, useState } from 'react';

import { Table, Button, Empty, notification } from "antd";

import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

import TableButtons from '../../components/TableButtons';

import REQUESTS from '../../api/requests'

import showPropsConfirm from '../../components/showPropsConfirm';

import BouquetsDrawer from './components/BouquetsDrawer';

import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/DataUserProfile/DataUserProfileSlise';

export default function Bouquets() {
    const [bouquets, setBouquets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [current, setCurrent] = useState(null)
    const { admin_permissions } = useSelector(selectUser);
    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
        },
        {
            title: 'Bouquet Name',
            dataIndex: 'bouquet_name',
            key: 'bouquet_name',
            align: 'center',
        },

        {
            title: 'Streams',
            dataIndex: "bouquet_channels",
            key: 'bouquet_channels',
            align: 'center',
            render: (text, record, index) => {
                let count = 0
                if (text) {
                    count = JSON.parse(text).length
                }

                return count
            }
        },
        {
            title: 'Movies',
            dataIndex: "bouquet_movies",
            key: 'bouquet_movies',
            align: 'center',
            render: (text, record, index) => {
                let count = 0
                if (text) {
                    count = JSON.parse(text).length
                }

                return count
            }
        },
        {
            title: 'Series',
            dataIndex: "bouquet_series",
            key: 'bouquet_series',
            align: 'center',
            render: (text, record, index) => {
                let count = 0
                if (text) {
                    count = JSON.parse(text).length
                }

                return count
            }
        },
        {
            title: 'Stations',
            dataIndex: "bouquet_radios",
            key: 'bouquet_radios',
            align: 'center',
            render: (text, record, index) => {
                let count = 0
                if (text) {
                    count = JSON.parse(text).length
                }

                return count
            }
        },
        {
            title: "",
            width: "60px",
            dataIndex: "action",
            align: 'center',
            render: (text, record, index) => (
                <div onClick={(e) => e.stopPropagation()}>
                    <TableButtons
                        handleMenuClick={(e) => handleMenuClick(e, record)}
                        buttons={[
                            {
                                key: "Edit",
                                text: "Edit",
                                icon: <EditOutlined />,
                                disabled: admin_permissions && admin_permissions["xui one"] &&
                                    !admin_permissions["xui one"]["Update bouquet"]
                            },
                            {
                                key: "Delete",
                                text: "Delete",
                                icon: <DeleteOutlined />,
                                disabled: admin_permissions && admin_permissions["xui one"] &&
                                    !admin_permissions["xui one"]["Delete bouquet"]
                            },
                        ]}
                    />
                </div>
            ),
        },
    ];

    const deleteFunc = (id) => {
        REQUESTS.XUI_CONFIG.DELETE_BOUQUET({ id }, () => {
            getBouquets()
        }, () => { })
    }

    const handleMenuClick = (e, record) => {
        switch (e.key) {
            case "Edit":
                setOpen(true)
                setCurrent(record)
                break;
            case "Delete":
                showPropsConfirm(record.id, deleteFunc, false, "Delete", "rgb(191, 67, 66)")
                break;
            default:
                break;
        }
    }

    const getBouquets = () => {
        setLoading(true)
        const callback = (data) => {
            setLoading(false)
            setBouquets(data)
        }
        REQUESTS.XUI_CONFIG.GET_BOUQUETS(callback, (err) => {
            notification.error({
                description: err,
            })
            setLoading(false)
        })
    }

    useEffect(() => {
        getBouquets()
    }, []);

    return (
        <div>
            <h1 className='page-title'>Bouquets</h1>
            <div className={styles['table-top-content']}>
                {admin_permissions && admin_permissions["xui one"] &&
                    admin_permissions["xui one"]["Add bouquet"] &&
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => setOpen(true)}
                    />
                }
            </div>

            <Table
                columns={columns}
                dataSource={bouquets}
                size='small'
                loading={loading}
                pagination={{
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                scroll={{ x: 300 }}
                bordered
                locale={{
                    emptyText: (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
                    ),
                }}
            />
            <BouquetsDrawer
                open={open}
                onClose={() => {
                    if (current) setCurrent(null);

                    setOpen(false);
                }}
                getBouquets={getBouquets}
                current={current}
                loading={loading}
            />
        </div>
    )
}
