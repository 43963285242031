const CONSTANTS = {
    JWT: "JWT",
    REMEMBER_ME: "REMEMBER_ME",

    APP_NAME: process.env.REACT_APP_APPLICATION_NAME || "XPLAYER ADMIN",

    API_HOST: process.env.REACT_APP_API_URL || "https://api.allnetcom.net",

    APP_COLOR: "black",
    LINK_COLOR: "white",
};

export default CONSTANTS;
