import React, { useEffect, useState } from 'react';
import { Tabs, Input, Button, notification } from 'antd';

import Movies from './Movies';
import Streams from './Streams';
import Series from './Series';
import REQUESTS from '../../../api/requests';
import Radios from './Radios';

import styles from "../index.module.scss";

const { TabPane } = Tabs;

export default function UpdateBouquete({ current, getBouquets, onClose }) {
    const [bouqueteName, setBouqueteName] = useState(current.bouquet_name);
    const [activeKey, setActiveKey] = useState("details");

    const [movies, setMovies] = useState([]);
    const [moviesIDs, setMoviesIDs] = useState([]);

    const [stremas, setStreams] = useState([]);
    const [stremasIDs, setStreamsIDs] = useState([]);

    const [series, setSeries] = useState([]);
    const [seriesIDs, setSeriesIDs] = useState([]);

    const [stations, setStations] = useState([]);
    const [stationsIDs, setStationsIDs] = useState([]);



    const next = () => {
        switch (activeKey) {
            case "details":
                setActiveKey("streams");
                break;
            case "streams":
                setActiveKey("movies");
                break;
            case "movies":
                setActiveKey("series");
                break;
            case "series":
                setActiveKey("stations");
                break;
            case "stations":
                setActiveKey("details");
                break;
            default:
                break;
        }
    }

    const back = () => {
        switch (activeKey) {
            case "streams":
                setActiveKey("details");
                break;
            case "movies":
                setActiveKey("streams");
                break;
            case "series":
                setActiveKey("movies");
                break;
            case "stations":
                setActiveKey("series");
                break;
            default:
                break;
        }
    }

    const toggle = (ids, setArray) => {
        const updatedIds = { ...ids };
        for (let key in updatedIds) {
            updatedIds[key] = !updatedIds[key];
        }
        setArray(updatedIds);
    }

    let updateIDs = (obj) => {
        let ids = { ...obj };

        for (let key in ids) {
            if (ids[key] === false) {
                delete ids[key]
            }
        }
        return Object.keys(ids);
    }

    const update = () => {
        const body = {
            id: current.id,
            bouquet_name: bouqueteName,
            stream: updateIDs(stremasIDs),
            series: updateIDs(seriesIDs),
            movies: updateIDs(moviesIDs),
            radios: updateIDs(stationsIDs),
        }

        REQUESTS.XUI_CONFIG.UPDATE_BOUQUET(body, (data) => {
            getBouquets();
            onClose();
        }, (error) => {
            notification.error({
                description: error
            })
        })
    }

    const getStreams = () => {
        const query = {
            action: "get_streams"
        }

        REQUESTS.XUI_CONFIG.GET_CONTENT(query, (res) => {
            let data = res.data;

            setStreams(data)

            let bouquetContentIds = [];
            let allContentIdsObj = {}

            if (current.bouquet_channels) bouquetContentIds = JSON.parse(current.bouquet_channels)

            data.map(item => {
                if (bouquetContentIds.includes(+item.id)) {
                    allContentIdsObj[item.id] = true
                } else {
                    allContentIdsObj[item.id] = false
                }
            })

            setStreamsIDs(allContentIdsObj)
        })
    }

    const getMovies = () => {
        const query = {
            action: "get_movies"
        }

        REQUESTS.XUI_CONFIG.GET_CONTENT(query, (res) => {
            let data = res.data;

            setMovies(data)

            let bouquetContentIds = [];
            let allContentIdsObj = {}

            if (current.bouquet_movies) bouquetContentIds = JSON.parse(current.bouquet_movies)

            data.map(item => {
                if (bouquetContentIds.includes(+item.id)) {
                    allContentIdsObj[item.id] = true
                } else {
                    allContentIdsObj[item.id] = false
                }
            })

            setMoviesIDs(allContentIdsObj)
        })
    }

    const getSeries = () => {
        const query = {
            action: "get_series_list"
        }

        REQUESTS.XUI_CONFIG.GET_CONTENT(query, (res) => {
            let data = res.data;

            setSeries(data);

            let bouquetContentIds = [];
            let allContentIdsObj = {}

            if (current.bouquet_series) bouquetContentIds = JSON.parse(current.bouquet_series)

            data.map(item => {
                if (bouquetContentIds.includes(+item.id)) {
                    allContentIdsObj[item.id] = true
                } else {
                    allContentIdsObj[item.id] = false
                }
            })

            setSeriesIDs(allContentIdsObj)
        })
    }

    const getStations = () => {
        const query = {
            action: "get_stations"
        }

        REQUESTS.XUI_CONFIG.GET_CONTENT(query, (res) => {
            let data = res.data;

            setStations(data)

            let bouquetContentIds = [];
            let allContentIdsObj = {}

            if (current.bouquet_radios) bouquetContentIds = JSON.parse(current.bouquet_radios);

            data.map(item => {
                if (bouquetContentIds.includes(+item.id)) {
                    allContentIdsObj[item.id] = true
                } else {
                    allContentIdsObj[item.id] = false
                }
            })

            setStationsIDs(allContentIdsObj)
        })
    }

    useEffect(() => {
        if (!current) return;

        getStreams();
        getMovies();
        getSeries();
        getStations()
    }, [current])

    return (
        <div>
            <Tabs
                defaultActiveKey="details"
                activeKey={activeKey}
                onChange={(key) => setActiveKey(key)}
                size="small"
                style={{
                    marginBottom: 32,
                }}
            >
                <TabPane tab="Details" key="details" >
                    <label className={styles['name-input']}>
                        <div className={styles['name']}> Bouquet Name</div>
                        <Input
                            value={bouqueteName}
                            onChange={(e) => setBouqueteName(e.target.value)}
                        />
                    </label>
                    <div className={styles['next-btn']}>
                        <Button onClick={next}>Next</Button>
                    </div>
                </TabPane>
                <TabPane tab="Streams" key="streams">
                    <Streams data={stremas} ids={stremasIDs} setIDs={setStreamsIDs} />
                    <div className={styles['table-bottom-btns']}>
                        <Button type='primary' onClick={back}>Previous</Button>
                        <div className={styles['toggle-next-btn']}>
                            <Button onClick={() => toggle(stremasIDs, setStreamsIDs)}>Toggle All</Button>
                            <Button onClick={next}>Next</Button>
                        </div>
                    </div>
                </TabPane>
                <TabPane tab="Movies" key="movies">
                    <Movies data={movies} ids={moviesIDs} setIDs={setMoviesIDs} />
                    <div className={styles['table-bottom-btns']}>
                        <Button type='primary' onClick={back}>Previous</Button>
                        <div className={styles['toggle-next-btn']}>
                            <Button onClick={() => toggle(moviesIDs, setMoviesIDs)}>Toggle All</Button>
                            <Button onClick={next}>Next</Button>
                        </div>
                    </div>
                </TabPane>
                <TabPane tab="Series" key="series">
                    <Series data={series} ids={seriesIDs} setIDs={setSeriesIDs} />
                    <div className={styles['table-bottom-btns']}>
                        <Button type='primary' onClick={back}>Previous</Button>
                        <div className={styles['toggle-next-btn']}>
                            <Button onClick={() => toggle(seriesIDs, setSeriesIDs)}>Toggle All</Button>
                            <Button onClick={next}>Next</Button>
                        </div>
                    </div>
                </TabPane>
                <TabPane tab="Stations" key="stations">
                    <Radios data={stations} ids={stationsIDs} setIDs={setStationsIDs} />
                    <div className={styles['table-bottom-btns']}>
                        <Button type='primary' onClick={back}>Previous</Button>
                        <div className={styles['toggle-next-btn']}>
                            <Button onClick={() => toggle(stationsIDs, setStationsIDs)}>Toggle All</Button>
                            <Button type='primary' onClick={update}>Edit Bouquet</Button>
                        </div>
                    </div>
                </TabPane>
            </Tabs>
        </div>
    )
}
