import { useSelector } from "react-redux";

import { selectUser } from "../../features/DataUserProfile/DataUserProfileSlise";

import UsedDevicesProgres from "./components/UsedDevicesProgres";
import PaymentStatistics from "./components/PaymentStatistics";
import ServerStatistics from "./components/ServerStatistics";
import DashboardCards from "./components/DashboardCards";
import OnlineUsersStatistics from "./components/OnlineUsersStatistics";

import classes from "./index.module.scss";
import "./styles/chartStyle.scss";

function Dashboard() {
  const { admin_permissions } = useSelector(selectUser);

  let dashboardPermissions = admin_permissions && admin_permissions["dashboard"];

  return dashboardPermissions["Show dashboard page"] && (
    <div>
      <DashboardCards dashboardPermissions={dashboardPermissions} />
      <div className={classes["dashboard-statistics"]}>
        <div className={classes["used-device-progress"]}>{dashboardPermissions["Device"] && <UsedDevicesProgres />}</div>
        {dashboardPermissions["Servers"] && <ServerStatistics />}
      </div>
      <div className={classes["statistics"]}>
        {dashboardPermissions["Payment"] && <PaymentStatistics />}
        {dashboardPermissions["Online users"] && <OnlineUsersStatistics />}
      </div>
    </div>
  );
}

export default Dashboard;
