import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Table, Button } from "antd";
import { PlusOutlined, MinusOutlined, DeleteOutlined, EditOutlined, LockOutlined, UnlockOutlined } from "@ant-design/icons";

import REQUESTS from "../../api/requests";

import getMyDate from "../../components/getMyDate";
import TableButtons from "../../components/TableButtons";
import showPropsConfirm from "../../components/showPropsConfirm";

import { getColumnSearchProps } from "../../utils/index";
import TEXT from "../../config/text";

import { selectwidgets } from "../../features/widgets/selector";
import { selectUser } from "../../features/DataUserProfile/DataUserProfileSlise";
import ResRefDrawer from "../referralReseller/components/ResRefDrawer";

import ActivationDrawer from "./components/ActivationDrawer";
import ResellerInfoDrawer from "./components/ResellerInfoDrawer";

import "./reseller.scss";

export default function Reseller() {
  const [loading, setLoading] = useState(false);
  const translation = useSelector((state) => state.translationData.translation);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState(["id", "DESC"]);
  const [search, setSearch] = useState({
    comment: "",
  });
  const [visible, setVisible] = useState(false);

  const [resellerDrawerInfo, setResellerDrawerInfo] = useState(false);
  const [current, setCurrent] = useState(null);

  const [editableReseller, setEditableReseller] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [addActivationDrawer, setAddActivationDrawer] = useState(false);
  const [activationType, setActivationType] = useState("");
  const [resellersData, setResellersdata] = useState([]);

  const widgets = useSelector(selectwidgets);

  const { admin_permissions } = useSelector(selectUser)

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: "60px",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: `${translation["Name"] || TEXT["Name"]}`,
      dataIndex: "name",
      key: "name",
      align: "center",
      ...getColumnSearchProps(),
    },
    {
      title: `${translation["Email"] || TEXT["Email"]}`,
      dataIndex: "email",
      key: "email",
      align: "center",
      ...getColumnSearchProps(),
    },
    {
      title: `${translation["Credits"] || TEXT["Credits"]}`,
      dataIndex: "total_activations",
      key: "total_activations",
      sorter: true,
      align: "center",
    },
    {
      title: `${translation["Activated devices"] || TEXT["Activated devices"]}`,
      dataIndex: "devices",
      key: "devices",
      align: "center",
      render: (text, record, index) => {
        if (record && record.devices) {
          return record.devices.length;
        }
      },
    },
    {
      title: `${translation["IP address"] || TEXT["IP address"]}`,
      dataIndex: "ip",
      key: "ip",
      align: "center",
      ...getColumnSearchProps(),
    },
    {
      title: `${translation["Subresellers count"] || TEXT["Subresellers count"]
        }`,
      dataIndex: "subresellers_count",
      key: "subresellers_count",
      sorter: true,
      align: "center",
    },
    {
      title: `${translation["Created date"] || TEXT["Created date"]}`,
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      sorter: true,
      render: (text, record, index) => {
        if (text) {
          return getMyDate(text);
        }
      },
    },
    {
      title: "",
      dataIndex: "is_disabled",
      align: "center",
      render: (text, record, index) => {
        return <div onClick={(e) => e.stopPropagation()}>
          <TableButtons
            handleMenuClick={(e) => handleMenuClick(e, record)}
            buttons={[
              {
                key: "edit",
                text: `${translation["Edit"] || TEXT["Edit"]}`,
                icon: <EditOutlined />,
                disabled: admin_permissions && admin_permissions["reseller"] && !admin_permissions["reseller"]["Update reseller/referral"]
              },
              {
                key: "delete",
                text: `${translation["Delete"] || TEXT["Delete"]}`,
                icon: <DeleteOutlined />,
                disabled: admin_permissions && admin_permissions["reseller"] && !admin_permissions["reseller"]["Delete reseller/referral"]
              },
              {
                key: "disable",
                text: resellersData[index]?.is_disabled
                  ? `${translation["Enable"] || TEXT["Enable"]}`
                  : `${translation["Disable"] || TEXT["Disable"]}`,
                icon: resellersData[index]?.is_disabled ? <LockOutlined /> : <UnlockOutlined />,
                disabled: admin_permissions && admin_permissions["reseller"] && !admin_permissions["reseller"]["Disable reseller/referral"]
              },
              {
                key: "Add Credits",
                text: translation["Add Credits"] || TEXT["Add Credits"],
                icon: <PlusOutlined />,
                disabled: admin_permissions && admin_permissions["reseller"] && !admin_permissions["reseller"]["Add credit"]
              },
              {
                key: "Take Credits",
                text:
                  translation["Take Credits"] || TEXT["Take Credits"],
                icon: <MinusOutlined />,
                disabled: admin_permissions && admin_permissions["reseller"] && !admin_permissions["reseller"]["Take credit"]
              },
            ]}
          />
        </div>
      },
    },
  ];

  const disableReseller = (item) => {
    const callback = () => {
      getResellers();
    };

    const errorCallback = (data) => { };

    REQUESTS.REFERRAL_RESELLER.DISABLE(item.id, callback, errorCallback);
  };

  const deleteReseller = (id) => {
    REQUESTS.RESELLERS_DELETE(() => { getResellers(); }, { id, })
  }

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "disable":
        showPropsConfirm(
          item,
          disableReseller,
          loading,
          `${translation["OK"] || TEXT["OK"]}`,
          "#bf4342",
          item.is_disabled
            ? `${translation["Are you sure you want to enable?"] ||
            TEXT["Are you sure you want to enable?"]
            }`
            : `${translation["Are you sure you want to disable?"] ||
            TEXT["Are you sure you want to disable?"]
            }`,
          `${translation["CANCEL"] || TEXT["CANCEL"]}`
        );
        break;

      case "edit":
        setEditableReseller(item);
        setVisible(true);
        break;
      case "delete":
        showPropsConfirm(
          item.id,
          deleteReseller,
          loading,
          "DELETE",
          "#bf4342",
          `${translation["Are you sure you want to delete?"] ||
          TEXT["Are you sure you want to delete?"]
          }`,
          `${translation["CANCEL"] || TEXT["CANCEL"]}`
        )
        break;
      case "Add Credits":
        setAddActivationDrawer(true);
        setSelectedItem(item);
        setActivationType("Add Credits");
        break;
      case "Take Credits":
        setAddActivationDrawer(true);
        setSelectedItem(item);
        setActivationType("Take Credits");
        break;
      default:
        break;
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    setSearch(filters);
    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const getResellers = () => {
    setLoading(true);
    const query = {
      page: currentPage,
      limit,
      pagination: 1,
      sort: JSON.stringify(sort),
    };

    if (!query.search) {
      query.search = {};
    }

    if (search.name) {
      query.search["name"] = search.name[0];
    }

    if (search.ip) {
      query.search["ip"] = search.ip[0];
    }

    if (search.email) {
      query.search["email"] = search.email[0];
    }

    function callback(data) {
      setLoading(false);
      setTotal(data.count);
      setLimit(data.limit);
      setResellersdata(data.rows);
    }

    function errorCallback(err) {
      setLoading(false);
    }
    query.search = JSON.stringify(query.search);

    REQUESTS.GET_RESELLERS(query, callback, errorCallback);
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      getResellers();
    }, 500);
    return () => clearTimeout(timeout);
  }, [search, currentPage, limit, sort]);

  return (
    <div className="reseller-page-container">
      {widgets &&
        widgets &&
        widgets.reseller &&
        widgets.reseller.enabled &&
        widgets.reseller.enabled === "false" && (
          <>
            <div className="pro-widget-warning-text">
              To use this feature you need to upgrade to Pro Version
              <br />
              Please contact us for more details.
            </div>
            <div className="pro-widget-tab-layer"></div>
          </>
        )}
      <p className={"reseller-top_title"}>
        {translation["Reseller"] || TEXT["Reseller"]}
      </p>
      {
        admin_permissions &&
        admin_permissions["reseller"] &&
        admin_permissions["reseller"]["Add reseller/referral"] &&
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setVisible(true)}
          className="reseller-page_add-edit-btn"
        />
      }
      <Table
        columns={columns}
        dataSource={resellersData}
        rowClassName={(record, index) =>
          resellersData[index].is_disabled ? "is_disabled" : "reseller-item-row"
        }
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              setResellerDrawerInfo(true);
              setCurrent(record);
            },
          };
        }}

        loading={loading}
        onChange={handleTableChange}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
        }}
        scroll={{ x: "max-content" }}
        size="small"
      />

      <ResRefDrawer
        visible={visible}
        onClose={() => {
          setVisible(false);
          setEditableReseller(null);
        }}
        current={editableReseller}
        getData={getResellers}
        followAddElement={{
          setCurrentPage,
          setSearch,
          setSort,
          setLimit,
        }}
        type="reseller"
      />

      <ActivationDrawer
        visible={addActivationDrawer}
        setVisible={() => {
          setAddActivationDrawer(false);
          setSelectedItem(null)
        }}
        getResellers={getResellers}
        selectedItem={selectedItem}
        activationType={activationType}
      />

      <ResellerInfoDrawer
        visible={resellerDrawerInfo}
        onClose={() => {
          setResellerDrawerInfo(false);
          setCurrent(null);
        }}
        current={current}
      />
    </div>
  );
}
