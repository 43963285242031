import axios from "axios";

const request = (method, url, body, callback, errorCallback, notTimeout) => {

  // let myAxios = axios.create();

  // myAxios.defaults.headers.common["Authorization"] = localStorage.getItem("TOKEN");
  // myAxios.defaults.headers.common["Content-Type"] = "application/x-www-form-urlencoded";

  // myAxios[method](url, method === "delete" ? { data: body } : body)

  let options = {
    url: url,
    method: method,
    data: body,
    headers: {
      // "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": localStorage.getItem("TOKEN"),
    },
    maxBodyLength: Infinity,
    maxContentLength: Infinity,
  }

  if (
    (url.endsWith("/editor/update_web_settings") && method.toLowerCase() === "put") ||
    (url.endsWith("/editor/reseller_translations") && method.toLowerCase() === "post")
  ) {
    options.headers["Content-Type"] = "application/x-www-form-urlencoded";
  }

  axios(options)
    .then((response) => {
      if (response.data.error) {
        if (response.data.message === "Forbiden") {
          window.location.href = "#";
        }

        if (errorCallback) {
          errorCallback(response.data.message);
        }
      } else {
        callback(response.data.message);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error.response.data.message);
      }
    });
};

export default request;
