import React, { useState } from 'react'

import { Drawer, Form, Input, Button, notification } from 'antd';

import REQUESTS from '../../../api/requests';

import UpdateBouquete from './UpdateBouquete';

import styles from "../index.module.scss"

export default function BouquetsDrawer({ open, onClose, getBouquets, current }) {

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true);

        REQUESTS.XUI_CONFIG.CREATE_BOUQUET(values, (data) => {
            setLoading(false);
            onClose();
            getBouquets();
        }, (error) => {
            setLoading(false);
            notification.error({
                description: error
            })
        })
    }

    return (
        <Drawer
            title={current ? "Edit Bouquet" : "Add Bouquet"}
            placement="right"
            onClose={onClose}
            open={open}
            width={current ? "70%" : 400}
        >
            {
                current ? <UpdateBouquete current={current} getBouquets={getBouquets} onClose={onClose} /> :
                    <Form
                        layout='vertical'
                        onFinish={onFinish}
                        form={form}
                        initialValues={{
                            bouquet_name: ""
                        }}
                    >
                        <Form.Item
                            name="bouquet_name"
                            label="Bouquet Name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input bouquet name!"
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <div className={styles['button-container']}>
                                <Button type="primary" htmlType="submit" loading={loading}>
                                    Add Bouquet
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
            }
        </Drawer>
    )
}
