import React from 'react'

import { Table, Button, Empty } from "antd";

import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

export default function Movies({ data, ids, setIDs }) {
    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
        },
        {
            title: 'VOD Name',
            dataIndex: 'stream_display_name',
            key: 'stream_display_name',
            align: 'center',
        },
        {
            title: 'Category',
            dataIndex: "",
            key: '',
            align: 'center',
        },
        {
            title: 'Actions',
            dataIndex: "",
            key: '',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <div>
                        {
                            ids[record.id] ?
                                <Button size='small' onClick={(e) => onClick(record.id)} style={{ borderColor: '#03b2cb', color: '#03b2cb' }}>
                                    <MinusOutlined />
                                </Button> :
                                <Button size='small' onClick={(e) => onClick(record.id)} style={{ borderColor: '#faab2f', color: '#faab2f' }}>
                                    <PlusOutlined />
                                </Button>
                        }
                    </div>
                )
            }
        },
    ];

    const onClick = (id) => {
        setIDs((prev) => ({ ...prev, [id]: !prev[id] }))
    }

    return (
        <div>
            <Table
                columns={columns}
                dataSource={data}
                pagination={{
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                bordered
                locale={{
                    emptyText: (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
                    ),
                }}
            />
        </div>
    )
}
