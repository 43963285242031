import React, { useState, useEffect } from 'react';

import moment from 'moment';

import { InputNumber, Form, Input, Switch, DatePicker } from 'antd';

export default function LineForm({
    form,
    onFinish,
    setDate,
    date,
    expire,
    setExpire
}) {

    const onChange = (checked) => {
        setExpire(checked)
    };

    const handleDateChange = (date, dateString) => {
        setDate(dateString);
    }

    useEffect(() => {
        if (date) form.setFieldsValue({ 'exp_date': moment(date, 'YYYY-MM-DD') });
    }, [date]);

    return (
        <div>
            <Form
                form={form}
                onFinish={onFinish}
                layout='vertical'
                initialValues={{
                    is_trial: false,
                    max_connections: 1,
                }}
            >
                <Form.Item name="username" label="Username">
                    <Input />
                </Form.Item>
                <Form.Item name="password" label="Password">
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="max_connections"
                    label="Max Connections"
                    rules={[
                        {
                            type: 'number',
                            min: 1,
                            message: "Please input a valid number!"
                        },
                    ]}
                >
                    <InputNumber
                        min={1}
                        formatter={value => `${value}`.replace(/[^0-9]/g, '')}
                        parser={value => value.replace(/[^0-9]/g, '')}
                    />
                </Form.Item>
                <div style={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)", gap: 10 }}>
                    <Form.Item name="exp_date" label="Expiry">
                        <DatePicker
                            disabled={expire}
                            format='YYYY-MM-DD'
                            defaultValue={date ? moment(date, 'YYYY-MM-DD') : null}
                            disabledDate={(current) => {
                                // Can not select days before today and today
                                return current && current < moment().endOf('day');
                            }}
                            onChange={handleDateChange}
                        />
                    </Form.Item>
                    <Form.Item
                        name="no_expire"
                        label="Never Expire"
                        valuePropName="checked"
                    >
                        <Switch onChange={onChange} checked={expire} />
                    </Form.Item>
                </div>
                <Form.Item
                    name="is_trial"
                    label="Trial Account"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
            </Form>
        </div>
    )
}