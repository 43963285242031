import { useEffect, useState } from 'react';

import { Button, Form, Select, Drawer } from 'antd';

import REQUESTS from '../../../api/requests';

import styles from "../index.module.scss"

export default function StreamFormat({ device, open, onClose, getDeviceManager }) {
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true)
        const body = {
            id: device?.id,
            stream_format: values.stream_format,
        };

        REQUESTS.STREAM_FORMAT(body, () => {
            setLoading(false);
            getDeviceManager();
            onClose();
        }, () => setLoading(false))
    };

    useEffect(() => {
        form.setFieldsValue({
            stream_format: device?.stream_format
        })
    }, [device])

    return <Drawer
        title='Edit Stream Format'
        open={open}
        onClose={onClose}
    >
        <Form
            form={form}
            onFinish={onFinish}
            layout='vertical'
        >
            <div className={styles['stream-format-select']}>
                <Form.Item
                    name="stream_format"
                    label="Stream Format"
                >
                    <Select
                        placeholder="Select a stream format"
                    >
                        <Select.Option value="default">default</Select.Option>
                        <Select.Option value="m3u8">m3u8</Select.Option>
                        <Select.Option value="ts">ts</Select.Option>
                    </Select>
                </Form.Item>
            </div>
            <Form.Item style={
                {
                    display: "flex",
                    justifyContent: "center"
                }
            }>
                <Button loading={loading} type='primary' htmlType='submit'>Save</Button>
            </Form.Item>
        </Form>
    </Drawer >
}
