import { useEffect, useState } from "react";
import { Table, Button, Popover } from "antd";
import TEXT from "../../../config/text";
import classes from '../style/devicePlaylists.module.scss'
import Createplaylist from "./CreatePlaylistDrawer";
import REQUESTS from "../../../api/requests";
import showPropsConfirm from "../../../components/showPropsConfirm";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckOutlined
} from "@ant-design/icons";
import getMyDate from "../../../components/getMyDate";
import { selectTranslation } from "../../../features/Translation/TranslationSlice";
import TableButtons from "../../../components/TableButtons";
import { useSelector } from "react-redux";

import { CloseOutlined } from '@ant-design/icons';
import PlaylistLogsDrawer from "./PlaylistLogsDrawer";
import ICONS from "../../../config/icons";

function DevicePlaylists({ userInfo }) {
  const [total, setTotal] = useState();
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [visible, setVisible] = useState(false);

  const [openlogs, setOpenlogs] = useState(false);

  const [ediPackage, setEdiPackage] = useState(null);
  const [deviceUserList, setDeviceUserList] = useState([]);
  const [selectedPlaylist,setSelectedPlaylist]=useState(null)

  const translation = useSelector(selectTranslation);

  const deleteTable = (id) => {
    const callback = (data) => {
      getDeviceUserList()
    };

    const errorCallback = (data) => { };

    REQUESTS.DEVICE.DELETE(id, callback, errorCallback);
  };

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "delete":
        showPropsConfirm(item.id, deleteTable, loading, `${translation["DELETE"] || TEXT["DELETE"]}`, "#bf4342")
        break;

      case "edit":
        setEdiPackage(item);
        setVisible(true);
        break;
      
      case "logs":
        setOpenlogs(true);
        setSelectedPlaylist(item)
        break;

      default:
        break;
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      align: "center",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: `${translation["Name"] || TEXT["Name"]}`,
      dataIndex: "name",
      align: "center",
      render: (record, text, index) => {
        return `${record}`;

      },
    },
    {
      title: `${translation["Url"] || TEXT["Url"]}`,
      dataIndex: "url",
      align: "center",
      render: (record, text, index) => {
        if (record) {
          const content = (
            <div>
              {record}
            </div>
          );
          return (
            <Popover content={content}>
              <div className={classes['ref-link']}>
                <a href={record} target={'_blank'}
                  style={{ color: "#1890ff" }}
                >{record}</a>
              </div>
            </Popover>
          );
        }
      }
    },

    {
      title: `${translation["Date"] || TEXT["Date"]}`,
      dataIndex: "createdAt",
      align: "center",
      render: (record, text, index) => {
        return record ? getMyDate(record) : "N/A"
      },

    },
    {
      title: `${translation["Protected"] || TEXT["Protected"]}`,
      dataIndex: "pin",
      align: "center",
      render: (text, record, index) => {
        return record?.is_protected ? record.pin : <CloseOutlined style={{ color: "red" }} />
      },
    },
    {
      title: `${translation["Status"] || TEXT["Status"]}`,
      dataIndex: "status",
      align: "center",
      render: (text, record, index) => {
        return record?.status  ||  "-"
      },
    },
    {
      title: `${translation["Expired date"] || TEXT["Expired date"]}`,
      dataIndex: "expired_date",
      align: "center",
      render: (record) => {
        if (record) {
          return getMyDate(record);
        } else {
          return "N/A";
        }
      },
    },
    {
      title: `${translation["Selected"] || TEXT["Selected"]}`,
      dataIndex: "is_selected",
      align: "center",
      render: (record) => {
        return record.is_selected ? <CheckOutlined style={{color:"green"}}/> : <CloseOutlined style={{ color: "red" }} />;
      },
    },
    {
      title: "",
      dataIndex: "action",
      align: "center",
      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          buttons={[
            {
              key: "edit",
              text: `${translation["Edit"] || TEXT["Edit"]}`,
              icon: <EditOutlined />,
            },
            {
              key: "delete",
              text: `${translation["Delete"] || TEXT["Delete"]}`,
              icon: <DeleteOutlined />,
            },
            {
              key: "logs",
              text: `${translation["Logs"] || TEXT["Logs"]}`,
              icon:ICONS.PLAYLIST_LOG ,
            },
          ]}
        />
      ),
    },

  ];

  const getDeviceUserList = () => {
    setLoading(true);
    REQUESTS.DEVICE.GET_DEVICE(userInfo?.id, { sort: JSON.stringify(["id", "DESC"]) }, (data) => {
      setTotal(data.count);
      setLoading(false);
      setDeviceUserList(data.playlists.reverse());
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const onClickAdd = () => {
    setVisible(true);
  };

  const closeDrawer = () => {
    setVisible(false);
    setEdiPackage(null);
  };

  useEffect(() => {
    getDeviceUserList();
  }, [userInfo]);

  return (
    <div className={classes["playlists_list_table"]}>
      <div className={classes["playlists_list_title"]}>
        <p className={classes["playlists_title-text"]}>Playlists</p>
        <Button type="primary" icon={<PlusOutlined />} onClick={onClickAdd} />
      </div>

      <Table
        loading={loading}
        rowKey="id"
        columns={columns}
        dataSource={deviceUserList}
        onChange={handleTableChange}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
        }}
        scroll={{ x: "max-content" }}
        size="small"
      />

      <Createplaylist
        visible={visible}
        userInfo={userInfo}
        onClose={closeDrawer}
        current={ediPackage}
        getData={getDeviceUserList}
        translation={translation}
      />

      <PlaylistLogsDrawer
        openlogs={openlogs}
        setOpenlogs={setOpenlogs}
        selectedPlaylist={selectedPlaylist}
      />
    </div>
  );
}

export default DevicePlaylists;