import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import { Table, Empty } from 'antd';

import REQUESTS from '../../../api/requests';

import styles from "../index.module.scss";

export default function SelectableTableBouquets({ selected, setSelected, toggleAll, getLine, onSelectChange }) {
    const [bouquets, setBouquets] = useState([]);

    const [loading, setLoading] = useState(false);

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
        },
        {
            title: 'Bouquet Name',
            dataIndex: 'bouquet_name',
            key: 'bouquet_name',
            align: 'center',
        },

        {
            title: 'Streams',
            dataIndex: "bouquet_channels",
            key: 'bouquet_channels',
            align: 'center',
            render: (text, record, index) => {
                let count = 0
                if (text) {
                    count = JSON.parse(text).length
                }

                return count
            }
        },
        {
            title: 'Movies',
            dataIndex: "bouquet_movies",
            key: 'bouquet_movies',
            align: 'center',
            render: (text, record, index) => {
                let count = 0
                if (text) {
                    count = JSON.parse(text).length
                }

                return count
            }
        },
        {
            title: 'Series',
            dataIndex: "bouquet_series",
            key: 'bouquet_series',
            align: 'center',
            render: (text, record, index) => {
                let count = 0
                if (text) {
                    count = JSON.parse(text).length
                }

                return count
            }
        },
        {
            title: 'Stations',
            dataIndex: "bouquet_radios",
            key: 'bouquet_radios',
            align: 'center',
            render: (text, record, index) => {
                let count = 0
                if (text) {
                    count = JSON.parse(text).length
                }

                return count
            }
        },
    ];

    const getBouquets = () => {
        setLoading(true);

        const callback = (data) => {
            setLoading(false);

            setBouquets(data);
        }

        REQUESTS.XUI_CONFIG.GET_BOUQUETS(callback, () => setLoading(false))
    }

    useEffect(() => {
        getBouquets()
    }, [])

    useEffect(() => {
        if (toggleAll) {
            setSelected(bouquets.map(bouquet => bouquet.id))
        } else {
            getLine()
        }
    }, [toggleAll])

    return (
        <Table
            columns={columns}
            dataSource={bouquets}
            rowClassName={(record) => {
                return classNames(styles["row"], { [styles['selected-row']]: selected.find(id => id == record.id) })
            }}
            onRow={(record) => ({
                onClick: () => {
                    onSelectChange(record.id);
                },
            })}
            loading={loading}
            bordered
            locale={{
                emptyText: (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
                ),
            }}
            scroll={{ x: 300 }}
        />
    )
}
