import REQUESTS from "../../api/requests";
import { useEffect, useState, useContext } from "react";
import { Table, Modal, DatePicker, Input, Button, Space, Radio } from "antd";
import classes from "./index.module.scss";
import MyIcon from "../../components/myicon";
import ICONS from "../../config/icons";
import { useSelector } from "react-redux";
import { selectTranslation } from "../../features/Translation/TranslationSlice";
import TEXT from "../../config/text";
import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate, createSearchParams, useSearchParams } from "react-router-dom";
import InitialParams from "../../components/InitialParams";
import getMyDate from "../../components/getMyDate";
import { emptyContext } from "../../context_empty/context_empty";
import macMaskInput from "../../components/macMaskInput";
const { RangePicker } = DatePicker;
const { confirm } = Modal;

function PaymentHistory() {
    const [empty, setEmpty] = useContext(emptyContext);
    const [searchParams] = useSearchParams();
    const [total, setTotal] = useState();
    const [limit, setLimit] = useState(10);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [date, setDate] = useState("");
    const [sort, setSort] = useState(["id", "DESC"]);
    const [search, setSearch] = useState({
        comment: "",
    });

    InitialParams(setLimit, setCurrentPage, setSearch, setSort);

    const translation = useSelector(selectTranslation);

    const navigate = useNavigate();

    const goToPosts = (params) =>
        navigate({
            search: `?${createSearchParams(params)}`,
        });

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
            return (
                <Input
                    allowClear
                    autoFocus
                    placeholder="Type text here"
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : []);
                        confirm({ closeDropdown: false });
                    }}
                />
            );
        },
        filterIcon: () => {
            return (
                <>
                    <SearchOutlined />
                </>
            );
        },
    });

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            width: "60px",
            fixed: "left",
            align: "center",
            render: (text, record, index) => {
                if (currentPage === 1) {
                    return index + 1;
                } else {
                    return limit * (currentPage - 1) + index + 1;
                }
            },
        },
        {
            title: `${translation["Date"] || TEXT["Date"]}`,
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            sorter: true,
            render: (record) => {
                return getMyDate(record);
            },
            filterDropdown: ({ }) => (
                <div
                    style={{
                        padding: 8,
                    }}
                >
                    <RangePicker
                        size="small"
                        onChange={(e, dateString) => setDate(dateString)}
                        renderExtraFooter={() => "extra footer"}
                    />
                </div>
            ),
            filterIcon: (filtered) => (
                <CalendarOutlined
                    style={{
                        color: JSON.parse(searchParams.get("between"))?.createdAt
                            ? "#1890ff"
                            : undefined,
                    }}
                />
            ),
        },
        {
            title: `${translation["Payment method"] || TEXT["Payment method"]}`,
            dataIndex: "method",
            key: "method",
            align: "center",
            ...getColumnSearchProps(),
        },
        {
            title: `${translation["Status"] || TEXT["Status"]}`,
            dataIndex: "status",
            key: "status",
            filters: [
                {
                    text: "COMPLETED",
                    value: "COMPLETED",
                },
                {
                    text: "PENDING",
                    value: "PENDING",
                },
                {
                    text: "REFUNDED",
                    value: "REFUNDED",
                },
            ],
            filterDropdown: ({ filters, setSelectedKeys, selectedKeys, confirm }) => {
                return (<>
                    <div style={{ padding: "6px 0 0 6px" }}>
                        <Radio.Group onChange={(e) => setSelectedKeys([e.target.value])} value={selectedKeys[0]} >
                            {
                                filters.map(filteredItem => (
                                    <div><Radio value={filteredItem.value}>{filteredItem.text}</Radio></div>
                                ))
                            }
                        </Radio.Group>

                    </div>
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Space>
                            <Button
                                type="link"
                                onClick={() => {
                                    setSelectedKeys([]);
                                    confirm();
                                }}
                                size="small"
                                style={{ width: 90 }}
                            >
                                Reset
                            </Button>

                            <Button
                                size="small"
                                style={{
                                    backgroundColor: "#000",
                                    color: '#fff',
                                }}
                                onClick={() => {
                                    confirm();
                                }}
                            >
                                OK
                            </Button>
                        </Space>
                    </div>
                </>
                )
            }
        },
        {
            title: `${translation["Amount"] || TEXT["Amount"]}`,
            dataIndex: "amount",
            key: "amount",
            align: "center",
            sorter: true,
        },
        {
            title: `${translation["Currency"] || TEXT["Currency"]}`,
            dataIndex: "currency",
            key: "currency",
            align: "center",
            ...getColumnSearchProps(),
        },

        {
            title: `${translation["Package Name"] || TEXT["Package Name"]}`,
            dataIndex: "package_name",
            align: "center",
            key: "package_name",
            ...getColumnSearchProps(),
            render: (record, text, index) => {
                if (record) {
                    return record;
                } else {
                    return "N/A";
                }
            },
        },

        {
            title: `${translation["Subscription id"] || TEXT["Subscription id"]}`,
            dataIndex: "subscription_id",
            key: "subscription_id",
            align: "center",
            render: (record, text, index) => {
                if (record) {
                    return record;
                } else {
                    return "N/A";
                }
            },
            ...getColumnSearchProps(),
        },

        {
            title: `${translation["Mac"] || TEXT["Mac"]}`,
            dataIndex: "mac",
            key: "mac",
            align: "center",
            ...macMaskInput(),
        },

        {
            title: `${translation["Device model"] || TEXT["Device model"]}`,
            dataIndex: "device_model",
            key: "device_model",
            align: "center",
            ...getColumnSearchProps(),
            render: (record, text, index) => {
                if (record) {
                    return record;
                } else {
                    return "N/A";
                }
            },
        },
    ];

    const getPaymentHistory = () => {
        setLoading(true);
        const query = {
            page: currentPage,
            limit,
            pagination: 1,
            sort: JSON.stringify(sort),
            search: {},
        };

        if (search.status) {
            query.search['status'] = search.status[0]
        }

        if (search.device_model) {
            query.search["device_model"] = search.device_model[0];
        }

        if (search.method) {
            query.search["method"] = search.method[0];
        }

        if (search.currency) {
            query.search["currency"] = search.currency[0];
        }

        if (search.subscription_id) {
            query.search["subscription_id"] = search.subscription_id[0];
        }

        if (search.ip) {
            query.search["ip"] = search.ip[0];
        }

        if (search.mac) {
            query.search["mac"] = search.mac[0];
        }

        if (search.package_name) {
            query.search["package_name"] = search.package_name[0];
        }

        if (search.email) {
            query.search["email"] = search.email[0];
        }

        if (query.search) {
            query.search = JSON.stringify(query.search);
        }

        if (date && date[0]) {
            let to = new Date(date[1]);
            to.setDate(to.getDate() + 1);
            query.between = JSON.stringify({
                createdAt: {
                    from: new Date(date[0]),
                    to: new Date(to),
                },
            });
        }

        goToPosts(query);

        REQUESTS.PAYMENTS.GET(query, (data) => {
            setTotal(data.count);
            // setLimit(data.limit);
            if (data.rows.length === 0 && currentPage > 1) {
                setCurrentPage((current) => current - 1);
            } else {
                setCurrentPage(data.currentPage);
            }
            setLoading(false);
            setPaymentHistory(data.rows);
            // if (!data.rows === []) {
            //     setEmpty(false);
            // }
        });
    };

    useEffect(() => {
        return () => {
            setEmpty(true);
        };
    }, []);

    const handleTableChange = (pagination, filters, sorter) => {

        if (sorter.field) {
            setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
        }

        setSearch(filters);
        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
    };

    useEffect(() => {
        let timout = setTimeout(() => {
            getPaymentHistory();
        }, 500);
        return () => {
            clearTimeout(timout);
        };
    }, [search, currentPage, limit, date, sort]);

    return (
        <div className={classes["PaymentHistory_list_table"]}>
            <div className={classes["PaymentHistory_list_title"]}>
                <p className={classes["PaymentHistory_title-text"]}>
                    {translation["Payment History"] || TEXT["Payment History"]}
                </p>
            </div>

            <Table
                loading={loading}
                rowKey="id"
                columns={columns}
                dataSource={paymentHistory}
                onChange={handleTableChange}
                pagination={{
                    position: ["bottomCenter"],
                    current: currentPage,
                    total: total,
                    pageSize: limit,
                    showSizeChanger: true,
                }}
                scroll={{ x: "max-content" }}
                size="small"
            />
        </div>
    );
}

export default PaymentHistory;
