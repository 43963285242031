import { useEffect, useState } from 'react';

import { Button, Table, notification, Empty, DatePicker } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined, CalendarOutlined } from '@ant-design/icons';

import AdminDrawer from './components/AdminDrawer';
import Permissions from './components/Permissions';
import getMyDate from '../../components/getMyDate';
import REQUESTS from "../../api/requests"
import styles from "./index.module.scss";
import TableButtons from '../../components/TableButtons';
import getColumnSearchProps from '../../components/getColumnSearchProps';
import showPropsConfirm from '../../components/showPropsConfirm';
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/DataUserProfile/DataUserProfileSlise';

const { RangePicker } = DatePicker;

export default function CreateAdminPage() {
    const [data, setData] = useState(false);
    const [limit, setLimit] = useState(10)
    const [total, setTotal] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState({});
    const [sort, setSort] = useState(["createdAt", "DESC"]);
    const [open, setOpen] = useState(false);
    const [openPermissionsDrawer, setOpenPermissionsDrawer] = useState(false);
    const [current, setCurrent] = useState(false);
    const [date, setDate] = useState(false);
    const [loading, setLoading] = useState(false);

    const { admin_permissions } = useSelector(selectUser);

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            align: "center",
            render: (text, record, index) => {
                if (currentPage === 1) {
                    return index + 1;
                } else {
                    return limit * (currentPage - 1) + index + 1;
                }
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            align: "center",
            ...getColumnSearchProps(),
            sorter: true,

        },
        {
            title: "Surname",
            dataIndex: "surname",
            key: "surname",
            align: "center",
            ...getColumnSearchProps(),
            sorter: true,

        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            align: "center",
            ...getColumnSearchProps("email"),
            sorter: true,
        },
        {
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
            align: "center",
            render: (text, record) => {
                return text || "-"
            }
        },
        {
            title: "Online",
            dataIndex: "online",
            key: "online",
            align: "center",
            sorter: true,
            render: (text, record) => {
                return record.online ? "Online" : "Offline";
            }
        },
        {
            title: "Crated Date",
            dataIndex: "createdAt",
            align: "center",
            sorter: true,
            render: (record) => {
                return getMyDate(record);
            },
            filterDropdown: ({ }) => (
                <div
                    style={{
                        padding: 8,
                    }}
                >
                    <RangePicker
                        size="small"
                        onChange={(e, dateString) => setDate(dateString)}
                        renderExtraFooter={() => "extra footer"}
                    />
                </div>
            ),
            filterIcon: (filtered) => (
                <CalendarOutlined />
            ),
        },
        {
            title: "",
            width: "50px",
            align: "center",
            render: (text, record, index) => (
                <TableButtons
                    handleMenuClick={(e) => handleMenuClick(e, record)}
                    buttons={[
                        {
                            key: "edit",
                            text: "Edit Profile",
                            icon: <EditOutlined />,
                            disabled: admin_permissions && admin_permissions["admins"] && !admin_permissions["admins"]["Update"]
                        },
                        {
                            key: "edit_permissions",
                            text: "Edit Permissions",
                            icon: <EditOutlined />,
                            disabled: admin_permissions && admin_permissions["admins"] && !admin_permissions["admins"]["Update permissions"]
                        },
                        {
                            key: "delete",
                            text: "Delete",
                            icon: <DeleteOutlined />,
                            disabled: admin_permissions && admin_permissions["admins"] && !admin_permissions["admins"]["Delete"]
                        },
                    ]}
                />
            ),
        },
    ];

    const handleMenuClick = (e, item) => {
        switch (e.key) {
            case "edit":
                setCurrent(item);
                setOpen(true);
                break;
            case "edit_permissions":
                setCurrent(item);
                setOpenPermissionsDrawer(true);
                break;
            case "delete":
                showPropsConfirm(
                    item.id,
                    deteleItem,
                    false,
                    "DELETE",
                    "#bf4342",
                );
                break;
        }
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setSearch(filters);
        if (sorter.field) {
            setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
        }
        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
    }

    const deteleItem = (id) => {
        REQUESTS.ADMINS.DELETE({ id }, () => {
            getData();
        })
    }

    const getData = () => {
        setLoading(true)

        const query = {
            page: currentPage,
            limit,
            sort: JSON.stringify(sort),
        };

        if (search.name) {
            query.search = { name: search.name[0] }
        };

        if (search.surname) {
            query.search = { surname: search.surname[0] };
        };

        if (search.email) {
            query.search = { email: search.email[0].trim() };
        }

        if (query.search) {
            query.search = JSON.stringify(query.search);
        }

        const callback = (data) => {
            setLoading(false);
            setTotal(data.count);
            setData(data.rows)
        };

        const errorCallback = (error) => {
            setLoading(false)
            notification.error({
                message: "Error",
                description: error,
            })
        };

        if (date && date[0]) {
            query.between = JSON.stringify({
                createdAt: {
                    from: date[0] + " 00:00",
                    to: date[1] + " 23:59",
                },
            });
        }

        REQUESTS.ADMINS.GET(query, callback, errorCallback)
    };

    useEffect(() => {
        let interval = setTimeout(() => {
            getData();
        }, 500);

        return () => {
            clearTimeout(interval);
        }

    }, [currentPage, limit, sort, search, date])

    return (
        <>
            <div className={styles['page-title-container']}>
                <h1 className='page-title'>Admins</h1>
                {
                    admin_permissions && admin_permissions["admins"] && admin_permissions["admins"]["Add"] &&
                    <Button
                        type='primary'
                        onClick={() => {
                            setOpen(true);
                            if (current) setCurrent(null)
                        }}
                    >
                        <PlusOutlined />
                    </Button>
                }
            </div>
            <Table
                dataSource={data}
                columns={columns}
                onChange={handleTableChange}
                pagination={{
                    total,
                    pageSize: limit,
                    current: currentPage,
                    showSizeChanger: true,
                    position: ["bottomCenter"],
                }}
                size='small'
                loading={loading}
                scroll={{ x: 300 }}
                locale={{
                    emptyText: (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
                    ),
                }}
            />

            <AdminDrawer
                open={open}
                onClose={() => {
                    setOpen(false);
                    if (current) setCurrent(null);
                }}
                current={current}
                getData={getData}
            />
            <Permissions
                open={openPermissionsDrawer}
                onClose={() => {
                    setOpenPermissionsDrawer(false);
                }}
                current={current}
            />
        </ >
    )
}
