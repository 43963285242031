import React, { useEffect, useState } from "react";

import { Table, Empty } from "antd";
import Icon, { FastBackwardOutlined } from "@ant-design/icons";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

import REQUESTS from "../../../api/requests";
import { useSelector } from "react-redux";
import { selectTranslation } from "../../../features/Translation/TranslationSlice";
import TableButtons from "../../../components/TableButtons";
import TEXT from "../../../config/text";
import ICONS from "../../../config/icons";

import { getColumnSearchProps } from "../../../utils/index";
import ResRefDrawer from "../../referralReseller/components/ResRefDrawer";
import showPropsConfirm from "../../../components/showPropsConfirm";
import ActivationDrawer from "./ActivationDrawer";
import ResellerInfoDrawer from "./ResellerInfoDrawer";

export default function ResellerSubresellers({ current }) {

  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [sort, setSort] = useState(["id", "DESC"]);

  const [search, setSearch] = useState(null);

  const [visible, setVisible] = useState(false);

  const [openActivationDrawer, setOpenActivationDrawer] = useState(false);

  const [activationType, setActivationType] = useState("Add Credits");

  const [resellerDrawerInfo, setResellerDrawerInfo] = useState(false);
  
  const [selectedItem, setSelectedItem] = useState(null);

  const translation = useSelector(selectTranslation);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      align: "center",
      // key:"id",
      // sorter: (a, b) => a.value - b.value,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: `${translation["Name"] || TEXT["Name"]}`,
      dataIndex: "name",
      ...getColumnSearchProps(),

      render: (record, text, index) => {
        return `${record}`;
      },
    },
    {
      title: `${translation["Email"] || TEXT["Email"]}`,
      dataIndex: "email",
      ...getColumnSearchProps(),
    },
    {
      title: `${translation["Credits"] || TEXT["Credits"]}`,
      dataIndex: "total_activations",
      sorter: true,
      align: "center",
      render: (record, text, index) => {
        return `${record}`;
      },
    },
    {
      title: `${translation["Activated devices"] || TEXT["Activated devices"]}`,
      dataIndex: "namdevicese",
      align: "center",
      render: (record, text, index) =>
        (record && record.devices && record.devices.length) || 0,
    },
    {
      title: `${translation["IP address"] || TEXT["IP address"]}`,
      dataIndex: "ip",
      align: "center",
      ...getColumnSearchProps(),
    },
    {
      title: `${translation["Subresellers count"] || TEXT["Subresellers count"]
        }`,
        align: "center",
      dataIndex: "subresellers_count",
      sorter: true,
      render: (record, text, index) => {
        return `${record}`;
      },
    },
    {
      title: "",
      align: "center",
      dataIndex: "is_disabled",
      render: (text, record, index) => {
        return <div onClick={(e) => e.stopPropagation()}>
          <TableButtons
            handleMenuClick={(e) => handleMenuClick(e, record)}
            buttons={[
              {
                key: "edit",
                text: `${translation["Edit"] || TEXT["Edit"]}`,
                icon: (<Icon
                  component={() => ICONS.PEN}
                />),
              },
              {
                key: "disable",
                text: data[index]?.is_disabled
                  ? `${translation["Enable"] || TEXT["Enable"]}`
                  : `${translation["Disable"] || TEXT["Disable"]}`,
                icon: data[index]?.is_disabled ? (<Icon
                  component={() => ICONS.Lock}
                />
                ) : (
                  <Icon
                    component={() => ICONS.PADLOCK}
                  />),
              },
              {
                key: "Add Credits",
                text: translation["Add Credits"] || TEXT["Add Credits"],
                icon: <PlusOutlined />,
              },
              {
                key: "Take Credits",
                text:
                  translation["Take Credits"] || TEXT["Take Credits"],
                icon: <MinusOutlined />,
              },
            ]}
          />
        </div>
      },
    },
  ];

  const disableReseller = (item) => {
    const callback = () => {
      getSubresellers();
    };

    const errorCallback = (data) => { };

    REQUESTS.REFERRAL_RESELLER.DISABLE(item.id, callback, errorCallback);
  };

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "disable":
        showPropsConfirm(
          item,
          disableReseller,
          loading,
          `${translation["OK"] || TEXT["OK"]}`,
          "#bf4342",
          item.is_disabled
            ? `${translation["Are you sure you want to enable?"] ||
            TEXT["Are you sure you want to enable?"]
            }`
            : `${translation["Are you sure you want to disable?"] ||
            TEXT["Are you sure you want to disable?"]
            }`,
          `${translation["CANCEL"] || TEXT["CANCEL"]}`
        );
        break;

      case "edit":
        setSelectedItem(item);
        setVisible(true);
        break;
      case "Add Credits":
        setOpenActivationDrawer(true);
        setSelectedItem(item);
        setActivationType("Add Credits");
        break;
      case "Take Credits":
        setOpenActivationDrawer(true);
        setSelectedItem(item);
        setActivationType("Take Credits");
        break;
      default:
        break;
    }
  };


  const getSubresellers = () => {
    const query = {
      filter: JSON.stringify({ parent_id: current?.id }),
      sort: JSON.stringify(sort),
    };

    if (search) {
      query.search = {};

      if (search.name) {
        query.search["name"] = search.name[0];
      }

      if (search.email) {
        query.search["email"] = search.email[0];
      }

      if (search.ip) {
        query.search["ip"] = search.ip[0];
      }

      query.search = JSON.stringify(query.search);
    }


    REQUESTS.RESELLER_SUBRESELLERS(query, (data) => {
      setLoading(false);
      setData(data);
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }

    setSearch(filters);
  };

  useEffect(() => {
    let timout;
    if (current) {
      timout = setTimeout(() => {
        getSubresellers();
      }, 500);
    }
    return () => {
      clearTimeout(timout);
    };
  }, [search, sort, current]);


  return (
    <div>
      <p className="reseller-infor-drawer-title">Subresellers</p>
      <Table
        loading={loading}
        rowKey="id"
        columns={columns}
        onChange={handleTableChange}
        dataSource={data}
        scroll={{ x: "max-content" }}
        size="small"
        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" /> }}
        rowClassName={(record, index) =>
          data[index].is_disabled ? "is_disabled" : "reseller-item-row"
        }
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              setResellerDrawerInfo(true);
              setSelectedItem(record);
            },
          };
        }}
      />
      <ResRefDrawer
        onClose={() => {
          setVisible(false);
          setSelectedItem(null);
        }}
        visible={visible}
        current={selectedItem}
        getData={getSubresellers}
        parentRes={current}
      />
      <ActivationDrawer
        visible={openActivationDrawer}
        setVisible={() => {
          setOpenActivationDrawer(false);
          setSelectedItem(null);
        }}
        getResellers={getSubresellers}
        selectedItem={selectedItem}
        activationType={activationType}
      />
      <ResellerInfoDrawer visible={resellerDrawerInfo}
        onClose={() => {
          setResellerDrawerInfo(false);
          setSelectedItem(null);
        }}
        current={selectedItem} 
       />
    </div>
  );
}
