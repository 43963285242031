import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Tabs } from "antd";

import Server from "../server";
import Languages from "../languages";
import SMTPConfig from "./components/SMTPconfig";
import ApplicationSettigs from "../application/components/AplicationSettings";
import { selectTranslation } from "../../features/Translation/TranslationSlice";
import { selectwidgets } from "../../features/widgets/selector";
import { useLocation, useNavigate, useSearchParams, createSearchParams } from "react-router-dom";
import PaymentSettings from "./components/PaymentSettings";
import ResellerTranslation from "./components/reseller-translations/ResellerTranslation"
import TEXT from "../../config/text";
import ICONS from "../../config/icons";
import useResize from "../../hooks/use-resize";
import "./index.scss";
import Branding from "./components/Branding";
import GoogleIMA from "./components/GoogleIMA";
import GoogleReCaptcha from "./components/GoogleReCaptcha";
import GEORestriction from "./components/GEORestriction";
import { selectUser } from "../../features/DataUserProfile/DataUserProfileSlise";


const { TabPane } = Tabs;

const Settings = () => {
  const location = useLocation();
  const translation = useSelector(selectTranslation);
  const widgets = useSelector(selectwidgets);
  const resize = useResize();

  const [searchParams, setSearchParams] = useSearchParams();

  const [actiaveKey, setActiveKey] = useState(location.search.split("=")[1]);

  const { admin_permissions } = useSelector(selectUser);

  useEffect(() => {
    if (actiaveKey) {
      setSearchParams({ 'page': actiaveKey })
    }
  }, [actiaveKey])

  return admin_permissions && admin_permissions["settings"] && (
    <div>
      <Tabs
        tabPosition={resize > 539 ? "left" : "top"}
        activeKey={actiaveKey}
        onChange={setActiveKey}
        className="settings-page-tabs-component"
      >
        {
          admin_permissions["settings"]["Show smtp"] && <TabPane tab={`SMTP`} key={"SMTP"}>
            <SMTPConfig />
          </TabPane>
        }
        {
          admin_permissions["settings"]["Show application/branding"] && <TabPane
            tab={translation["Application"] || TEXT["Application"]}
            key={"applicationSettigs"}
          >
            <ApplicationSettigs />
          </TabPane>
        }

        {admin_permissions["settings"]["Show application/branding"] && <TabPane
          tab={translation["Branding"] || TEXT["Branding"]}
          key={"branding"}
        >
          <Branding />
        </TabPane>}

        {
          admin_permissions["settings"]["Show payment settings"] && <TabPane
            tab={translation["Payment settings"] || TEXT["Payment settings"]}
            key={"paymentSettings"}
          >
            <PaymentSettings />
          </TabPane>
        }

        {
          admin_permissions["settings"]["Show server"] &&
          <TabPane tab={translation["Server"] || TEXT["Server"]} key={"server"}>
            <Server />
          </TabPane>
        }

        {
          admin_permissions["settings"]["Show languages"] &&
          <TabPane
            tab={translation["Languages"] || TEXT["Languages"]}
            key={"languages"}
          >
            <Languages />
          </TabPane>
        }
        {
          admin_permissions["settings"]["Update reseller translation"] &&
          <TabPane tab={"Reseller translation"} key={"reseller-translation"}>
            <ResellerTranslation />
          </TabPane>
        }
        {admin_permissions["settings"]["Show google ima"] && <TabPane tab={<>Google IMA {ICONS.PRO}</>} key={"google-IMA"}>
          <GoogleIMA />
        </TabPane>}

        {admin_permissions["settings"]["Show google recaptcha"] && <TabPane tab={<>Google reCAPTCHA</>} key={"google-reCAPTCHA"}>
          <GoogleReCaptcha />
        </TabPane>}

        {
          admin_permissions["settings"]["Show geo restriction"] && <TabPane tab={<>GEO Restriction {ICONS.PRO}</>} key={"GEO Restriction"}>
            <GEORestriction />
          </TabPane>
        }
      </Tabs>
    </div>
  );
};

export default Settings;
