import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Button, Select, Table, Badge, Empty, notification } from "antd";

import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

import TableButtons from '../../components/TableButtons';

import getColumnSearchProps from '../../components/getColumnSearchProps';

import REQUESTS from '../../api/requests';

import LinesDrawer from './components/LinesDrawer';

import getMyDate from "../../components/getMyDate"

import showPropsConfirm from '../../components/showPropsConfirm';

import { selectUser } from '../../features/DataUserProfile/DataUserProfileSlise';

import styles from './index.module.scss';

export default function Lines() {
    const [lines, setLines] = useState([]);

    const [loading, setLoading] = useState(false);

    const [filter, setFilter] = useState("No filter");
    const [search, setSearch] = useState("");

    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);

    const [open, setOpen] = useState(false);
    const [current, setCurrent] = useState(false);

    const [sort, setSort] = useState("desc");

    const { admin_permissions } = useSelector(selectUser);

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            sorter: true
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            align: 'center',
            ...getColumnSearchProps()
        },
        {
            title: 'Password',
            dataIndex: 'password',
            key: 'password',
            align: 'center',
            ...getColumnSearchProps()
        },
        {
            title: 'Owner',
            dataIndex: 'owner_name',
            key: 'owner_name',
            align: 'center',
        },
        {
            title: 'Status',
            dataIndex: 'enabled',
            key: 'enabled',
            align: 'center',
            render: (text) => <Badge color={text == 1 ? "green" : "grey"} />
        },
        {
            title: 'Online',//?
            dataIndex: '',
            key: '',
            align: 'center',
        },
        {
            title: 'Trial',
            dataIndex: 'is_trial',
            key: 'is_trial',
            align: 'center',
            render: (text) => <Badge color={text == 1 ? "yellow" : "grey"} />
        },
        {
            title: 'Restreamer',
            dataIndex: 'is_restreamer',
            key: 'is_restreamer',
            align: 'center',
            render: (text) => <Badge color={text == 1 ? "yellow" : "grey"} />
        },
        {
            title: 'Active',
            dataIndex: "active_connections",
            key: 'active_connections',
            align: 'center',
        },
        {
            title: 'Connections',
            dataIndex: "max_connections",
            key: 'max_connections',
            align: 'center',
        },
        {
            title: 'Expiration',
            dataIndex: "exp_date",
            key: 'exp_date',
            align: 'center',
            render: (text, record) => text ? getMyDate(new Date(parseInt(text) * 1000)) : "-"
        },
        {
            title: 'Last Connection', //?
            dataIndex: "",
            key: '',
            align: 'center',
        },
        {
            title: "",
            width: "60px",
            dataIndex: "action",
            align: 'center',
            render: (text, record, index) => (
                <div onClick={(e) => e.stopPropagation()}>
                    <TableButtons
                        handleMenuClick={(e) => handleMenuClick(e, record)}
                        buttons={[
                            {
                                key: "Edit",
                                text: "Edit",
                                icon: <EditOutlined />,
                                disabled: admin_permissions && admin_permissions["xui one"] &&
                                    !admin_permissions["xui one"]["Update line"]
                            },
                            {
                                key: "Delete",
                                text: "Delete",
                                icon: <DeleteOutlined />,
                                disabled: admin_permissions && admin_permissions["xui one"] &&
                                    !admin_permissions["xui one"]["Delete line"]
                            },
                        ]}
                    />
                </div>
            ),
        },
    ];

    const handleDelete = (id) => {
        REQUESTS.XUI_CONFIG.DELETE_LINE({ id }, (data) => {
            getLines();
        })
    }

    const handleMenuClick = (e, record) => {
        switch (e.key) {
            case "Edit":
                setCurrent(record);
                setOpen(true);
                break;
            case "Delete":
                showPropsConfirm(record.id, handleDelete, false, "Delete", "rgb(191, 67, 66)")
                break;

            default:
                break;
        }
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setSort(sorter.order == "ascend" ? "asc" : "desc")
        setCurrentPage(pagination.current)
        setLimit(pagination.pageSize);
        setSearch(filters)
    }

    const getLines = () => {
        let query = {
            limit,
            order: sort,
            start: (currentPage - 1) * limit,
        }

        setLoading(true);

        if (search.username) {
            query.type = "search";
            query.params = search.username[0];
        } else if (search.password) {
            query.type = "search";
            query.params = search.password[0];
        } else if (filter) {
            query.type = "filter";
            query.params = filter;
        }

        const callback = (data) => {
            setLoading(false);
            setTotal(data.recordsTotal);
            setLines(data.data);
        }

        const errorCallback = (error) => {
            setLoading(false);
            notification.error({
                message: 'Error',
                description: error,
            })
        }

        query = Object.keys(query).length ? query : null;

        REQUESTS.XUI_CONFIG.GET_LINES(query, callback, errorCallback)
    }

    useEffect(() => {
        let timeoutId = null;

        timeoutId = setTimeout(() => {
            getLines()
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [filter, limit, search, currentPage, sort])

    return (
        <div>
            <h1 className='page-title'>Lines</h1>
            <div className={styles['search-elements-container']}>
                <Select
                    defaultValue={filter}
                    style={{
                        width: 250,
                    }}
                    onChange={setFilter}
                    options={[
                        {
                            value: "No filter",
                            label: "No filter",
                        },
                        {
                            value: "Active",
                            label: "Active",
                        },
                        {
                            value: "Disabled",
                            label: "Disabled",
                        },
                        {
                            value: 'Banned',
                            label: 'Banned',
                        },
                        {
                            value: 'Expired',
                            label: 'Expired',
                        },
                        {
                            value: 'Trial',
                            label: 'Trial',
                        },
                    ]}
                />

                {
                    admin_permissions && admin_permissions["xui one"] &&
                    admin_permissions["xui one"]["Add line"] &&
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => setOpen(true)}
                    />
                }
            </div>

            <Table
                columns={columns}
                dataSource={lines}
                size='small'
                loading={loading}
                pagination={{
                    position: ["bottomCenter"],
                    current: currentPage,
                    pageSize: limit,
                    total,
                    showSizeChanger: true,
                    pageSizeOptions: [10, 25, 250],//500,1000
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                onChange={handleTableChange}
                scroll={{ x: 300 }}
                bordered
                locale={{
                    emptyText: (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
                    ),
                }}
            />

            <LinesDrawer
                open={open}
                onClose={() => {
                    setOpen(false);

                    if (current) setCurrent(null)
                }}
                current={current}
                getLines={getLines}
            />
        </div>
    )
}
