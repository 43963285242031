import { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { selectTranslation } from "../../../features/Translation/TranslationSlice";
import TEXT from "../../../config/text";
import REQUESTS from "../../../api/requests";
import classes from "../style/activationDrawer.module.scss";
import ErrorMessage from "../../../components/ErrorMessage";

import { Button, Form, InputNumber, Drawer, Select } from "antd";
const { Option } = Select;

const ActivationDrawer = ({ onClose, visible, current, getData }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [getPackages, setGetPackages] = useState();
    const [message, setMessage] = useState({
        type: false,
        text: "",
    });
    const [amount, setAmount] = useState(0);

    const translation = useSelector(selectTranslation);

    const getMytPackages = () => {
        const collback = (data) => {
            setGetPackages(data.rows);
        };
        REQUESTS.DEVICE.GET_PACKAGES(collback);
    };

    useEffect(() => {
        if (visible && current) getMytPackages();
    }, [current, visible]);

    const onValuesChange = (e) => {
        setMessage({
            type: false,
            text: "",
        });
    };

    useEffect(() => {
        if (!visible) {
            setLoading(false);
            onValuesChange();
        }
    }, [visible]);

    const onFinish = (values) => {
        setLoading(true);

        const body = {
            package_id: Number(values.packages) || getPackages[0]?.id,
            id: current.id,
            amount: amount
        };

        function callback() {
            setLoading(false);
            onClose();
            getData();
        }

        function errorCallback(err) {
            setLoading(false);
            setMessage({ type: false, text: err });
        }

        REQUESTS.DEVICE.ACTIVATE_DEVICE(body, callback, errorCallback);
    };

    return (
        <Drawer
            title={`${translation["Activate"] || TEXT["Activate"]} `}
            placement="right"
            onClose={onClose}
            open={visible}
        >
            <Form
                form={form}
                name="profile"
                layout="vertical"
                onFinish={onFinish}
                onValuesChange={onValuesChange}
                initialValues={{
                    password: "",
                }}
            >
                <Form.Item
                    className={classes["form_item_currencies"]}
                    name="packages"
                    label={translation["Credits"] || TEXT["Credits"]}
                >
                    {getPackages ? (
                        <Select
                            // showSearch={true}
                            defaultValue={`${getPackages[0]?.name}`}
                            className={classes["select"]}
                        >
                            {getPackages.map((item) => {
                                return (
                                    <Option key={item.id} value={`${item.id}`}>
                                        {item.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    ) : (
                        ""
                    )}
                </Form.Item >
                <div style={{ width: "100%" }}>
                    <Form.Item name='amount' label={translation["Price"] || TEXT["Price"]}>
                        <InputNumber min={0} defaultValue={0} style={{ width: '100%' }} onChange={(value) => setAmount(value)} />
                    </Form.Item>
                </div>


                <ErrorMessage message={message} />
                <div className={classes["save_button_div"]}>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className={classes["save_button"]}
                            loading={loading}
                        >
                            {translation["Save"] || TEXT["Save"]}
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </Drawer>
    );
};

export default ActivationDrawer;
