import { useState, useEffect } from "react";

import TEXT from "../../../config/text";
import REQUESTS from "../../../api/requests";
import classes from "../style/createplaylist.module.scss";
import ErrorMessage from "../../../components/ErrorMessage";
import { Button, Form, Input, Drawer, Checkbox } from "antd";
import { SafetyCertificateOutlined } from "@ant-design/icons";

const Createplaylist = ({
  onClose,
  visible,
  current,
  getData,
  translation,
  userInfo,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    type: false,
    text: "",
  });

  const [isProtected, setIsProtected] = useState(false);

  const onValuesChange = (e) => {
    setMessage({
      type: false,
      text: "",
    });
  };

  useEffect(() => {
    if (visible === false) {
      setLoading(false);
      onValuesChange();
    }
  }, [visible]);

  const onFinish = (values) => {
    setLoading(true);

    let body = {
      name: values.name,
      url: values.M3U8_url,
    };

    if (current) {
      body["id"] = current.id;
      body["is_protected"] = isProtected;
      
      if (!current.is_protected && isProtected) {
        body["pin"] = values.pin;
        body["confirm_pin"] = values.confirm_pin;
      }
    } else {
      body["deviceId"] = userInfo.id;
      if (isProtected) {
        body["pin"] = values.pin;
        body["confirm_pin"] = values.confirm_pin;
        body["is_protected"] = isProtected;
      }
    }

    function callback() {
      setLoading(false);
      onClose();
      getData();
    }

    function errorCallback(err) {
      setLoading(false);
      setMessage({ type: false, text: err });
    }
    if (current) {
      REQUESTS.DEVICE.EDIT_PLAYLIST(body, callback, errorCallback);
    } else {
      REQUESTS.DEVICE.ADD(body, callback, errorCallback);
    }
  };

  useEffect(() => {
    if (current) {
      form.setFields([
        {
          name: "name",
          value: current.name,
        },

        {
          name: "M3U8_url",
          value: current.url,
        },
      ]);

      setIsProtected(current.is_protected);
    } else {
      form.resetFields();
      setIsProtected(false);
    }
  }, [current, visible]);

  return (
    <Drawer
      title={
        current
          ? translation["Edit Playlist"] || TEXT["Edit Playlist"]
          : translation["Add Playlist"] || TEXT["Add Playlist"]
      }
      placement="right"
      onClose={onClose}
      open={visible}
    >
      <Form
        form={form}
        name="profile"
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        initialValues={{
          password: "",
        }}
      >
        <Form.Item
          label={translation["Name"] || TEXT["Name"]}
          name="name"
          rules={[
            {
              required: true,
              message: "Please input name",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translation["M3U8 URL"] || TEXT["M3U8 URL"]}
          name="M3U8_url"
          rules={[
            {
              required: true,
              message: "Please input M3U8 url",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Checkbox
          checked={isProtected}
          onChange={(e) => setIsProtected(e.target.checked)}
          className={classes["protected-playlist-checkbox"]}
        >
          {translation["Protect Playlist"] || TEXT["Protect Playlist"]}
        </Checkbox>

        <Form.Item
          name="pin"
          label={translation["Set PIN"] || TEXT["Set PIN"]}
          rules={[
            {
              required: current && current.is_protected ? false : isProtected,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password
            disabled={(current && current.is_protected) || !isProtected}
          />
        </Form.Item>

        <Form.Item
          name="confirm_pin"
          dependencies={["pin"]}
          label={translation["Confirm PIN"] || TEXT["Confirm PIN"]}
          rules={[
            {
              required: current && current.is_protected ? false : isProtected,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("pin") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password
            disabled={(current && current.is_protected) || !isProtected}
          />
        </Form.Item>
        <ErrorMessage message={message} />
        <div className={classes["playlist_save_div"]}>
          <Form.Item>
            <Button
              type="primary"
              onClick={() => form.submit()}
              className={classes["playlist_save"]}
              loading={loading}
            >
              {translation["Save"] || TEXT["Save"]}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Drawer>
  );
};

export default Createplaylist;
