import React, { useState, useEffect } from 'react';

import moment from 'moment';

import { Drawer, Form, Tabs, Button, notification } from 'antd';

import useResize from '../../../hooks/use-resize';

import LineForm from './LineForm';

import SelectableTableBouquets from './SelectableTableBouquets';

import REQUESTS from '../../../api/requests';

import getDate from '../../../components/getDate';

import styles from '../index.module.scss';

export default function LinesDrawer({ current, open, onClose, getLines }) {
    const [selectedBouquete, setSelectedBouquete] = useState([]);

    const [toggleAll, setToggleAll] = useState(false);

    const [activeKey, setActiveKey] = useState('Details');

    const [date, setDate] = useState(moment().endOf('day').add(1, 'month'));

    const [expire, setExpire] = useState(false);

    const [form] = Form.useForm();

    const windowWidth = useResize();

    const toggle = () => {
        setToggleAll(!toggleAll);
    }

    const onSelectChange = (id) => {
        let array = [...selectedBouquete];

        let result = [];

        if (array.includes(id)) {
            result = array.filter(item => item != id);
        } else {
            result = [...array, id];
        }
        setSelectedBouquete(result);
    };

    const getLine = () => {
        if (!current) return
        // get line for selected bouquete
        REQUESTS.XUI_CONFIG_LINE({ id: current.id }, (data) => {
            let bouquets = data.data.bouquet;
            try {
                bouquets = JSON.parse(bouquets);
            } catch (error) {
                bouquets = [];
                console.error(error)
            }
            setSelectedBouquete(bouquets)
        },)
    }

    const onFinish = (values) => {
        const { username, password, max_connections, no_expire, is_trial, exp_date } = values;

        const body = {
            username,
            password,
            max_connections: max_connections || 1,
            is_trial,
            bouquets_selected: selectedBouquete,
            no_expire: expire
        }

        if (!expire && exp_date && date) body.exp_date = date

        if (current) {
            body.id = current.id;

            REQUESTS.XUI_CONFIG.UPDATE_LINE(body, (data) => {
                onClose()
                getLines()
            }, (error) => {
                notification.error({
                    description: error
                })
            })
        } else {
            REQUESTS.XUI_CONFIG.CREATE_LINE(body, (data) => {
                onClose()
                getLines()
                if (data.status == "STATUS_EXISTS_USERNAME") {
                    notification.warning({
                        description: "Username already exists"
                    })
                }
            }, (error) => {
                notification.error({
                    description: error
                })
            })
        }
    }

    useEffect(() => {
        if (current) {
            let expireDate = current.exp_date ?
                moment(getDate(new Date(current.exp_date * 1000)), 'DD/MM/YYYY').format('YYYY-MM-DD') :
                moment().endOf('day').add(1, 'month');

            setDate(expireDate);
            setExpire(!current.exp_date);

            form.setFieldsValue({
                username: current.username,
                password: current.password,
                max_connections: +current.max_connections || 1,
                no_expire: !current.exp_date,
                is_trial: !(current.is_trial == "0"),
                exp_date: moment(expireDate, 'YYYY-MM-DD')
            });

            getLine()
        }
    }, [current])

    useEffect(() => {
        if (!open) {
            form.resetFields();
            setSelectedBouquete([])
            setToggleAll(false)
            setActiveKey('Details')
            setExpire(false)
            setDate(moment().endOf('day').add(1, 'month'))
        }
    }, [open])

    return (
        <Drawer
            title={current ? "Edit Line" : "Add Line"}
            placement="right"
            onClose={onClose}
            open={open}
            width={windowWidth < 800 ? "95%" : "50%"}
            destroyOnClose={true}
        >
            <Tabs
                defaultActiveKey="Details"
                activeKey={activeKey}
                onChange={setActiveKey}
                forceRender={true}
            >
                <Tabs.TabPane tab="Details" key="Details">
                    <LineForm
                        onFinish={onFinish}
                        form={form}
                        date={date}
                        setDate={setDate}
                        expire={expire}
                        setExpire={setExpire}
                    />
                    <div className={styles['next-btn']}>
                        <Button onClick={() => setActiveKey("Bouquets")}>Next</Button>
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Bouquets" key="Bouquets">
                    <SelectableTableBouquets
                        selected={selectedBouquete}
                        setSelected={setSelectedBouquete}
                        toggleAll={toggleAll}
                        getLine={getLine}
                        onSelectChange={onSelectChange}
                    />
                    <div className={styles['table-bottom-btns']}>
                        <Button onClick={() => setActiveKey("Details")}>Previous</Button>
                        <div className={styles['toggle-next-btn']}>
                            <Button onClick={toggle}>
                                Toggle All
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => form.submit()}
                            >
                                {current ? "Edit Line" : "Add Line"}
                            </Button>
                        </div>
                    </div>
                </Tabs.TabPane>
            </Tabs>
        </Drawer >
    )
}
