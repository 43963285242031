import { useEffect, useState } from "react";

import { useNavigate, createSearchParams, useSearchParams } from "react-router-dom";

import { useSelector } from "react-redux";

import { Table, DatePicker, } from "antd";

import {
    CalendarOutlined,
    ExpandAltOutlined,
    TagOutlined,
    DeleteOutlined,
    EditOutlined
} from "@ant-design/icons";

import REQUESTS from "../../api/requests";

import TEXT from "../../config/text";
import ICONS from "../../config/icons";

import getMyDate from "../../components/getMyDate";
import showPropsConfirm from "../../components/showPropsConfirm";
import InitialParams from "../../components/InitialParams";
import TableButtons from "../../components/TableButtons";
import macMaskInput from "../../components/macMaskInput";
import getColumnSearchProps from "../../components/getColumnSearchProps";

import { selectTranslation } from "../../features/Translation/TranslationSlice";
import { selectUser } from "../../features/DataUserProfile/DataUserProfileSlise";

import ActivationDrawer from "./components/ActivationDrawer";
import DeviceManagerDrawer from "./components/DeviceManagerDrawer";
import StreamFormat from "./components/StreamFormat";

import classes from "./index.module.scss";

const { RangePicker } = DatePicker;

function DeviceManager() {
    const [searchParams] = useSearchParams();

    const [total, setTotal] = useState(10);
    const [limit, setLimit] = useState(10);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [visible, setVisible] = useState(false);
    const [activationVisible, setActivationVisible] = useState();
    const [deviceManager, setDeviceManager] = useState([]);
    const [editDevice, setEditDevice] = useState(null);
    const [date, setDate] = useState("");
    const [expiredDate, setExpiredDate] = useState("");
    const [activationExpired, setActivationExpired] = useState();
    const [userInfo, setUserInfon] = useState(null);
    const [sort, setSort] = useState(["online", "ASC"]);
    const [search, setSearch] = useState({});
    const [showStreamFormat, setShowStreamFormat] = useState(false);

    const { admin_permissions } = useSelector(selectUser);

    InitialParams(setLimit, setCurrentPage, setSearch, setSort);

    const navigate = useNavigate();

    const goToPosts = (params) =>
        navigate({
            search: `?${createSearchParams(params)}`,
        });

    const translation = useSelector(selectTranslation);

    const handleMenuClick = (e, item) => {
        switch (e.key) {
            case "free_trial":
                showPropsConfirm(
                    item.id,
                    free_trial,
                    loading,
                    `${translation["Free trial"] || TEXT["Free trial"]}`,
                    "#28857c",
                    `${translation["Are you sure you want to free trial?"] ||
                    TEXT["Are you sure you want to free trial?"]
                    }`,
                    `${translation["CANCEL"] || TEXT["CANCEL"]}`
                );
                break;
            case "activate":
                setEditDevice(item);
                setActivationVisible(true);
                break;

            case "stream_format":
                setEditDevice(item);
                setShowStreamFormat(true);
                break;

            case "deactivate":
                showPropsConfirm(
                    item.id,
                    deactivateDevice,
                    loading,
                    `${translation["DEACTIVATE"] || TEXT["DEACTIVATE"]}`,
                    "#bf4342",
                    `${translation["Are you sure you want to deactivate device?"] ||
                    TEXT["Are you sure you want to deactivate device?"]
                    }`,
                    `${translation["CANCEL"] || TEXT["CANCEL"]}`
                );
                break;

            case "delete":
                showPropsConfirm(
                    item.id,
                    deleteTable,
                    loading,
                    `${translation["DELETE"] || TEXT["DELETE"]}`,
                    "#bf4342",
                    `${translation["Are you sure you want to delete?"] ||
                    TEXT["Are you sure you want to delete?"]
                    }`,
                    `${translation["CANCEL"] || TEXT["CANCEL"]}`
                );
                break;
            default:
                break;
        }
    };

    const deleteTable = (id) => REQUESTS.DEVICE.DELETE_DEVICE(id, (data) => getDeviceManager());

    const deactivateDevice = (id) => REQUESTS.DEVICE_DEACTIVEATE({ id }, () => getDeviceManager())

    const free_trial = (id) => REQUESTS.DEVICE.FREE_TRIAL({ id }, () => getDeviceManager());

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            key: "id",
            align: "center",
            render: (text, record, index) => limit * (currentPage - 1) + index + 1
        },

        {
            title: `${translation["Mac"] || TEXT["Mac"]}`,
            align: "center",
            dataIndex: "mac",
            ...macMaskInput(),
        },
        {
            title: `${translation["Model"] || TEXT["Model"]}`,
            align: "center",
            dataIndex: "model",
            key: "model",
            ...getColumnSearchProps("model"),
            render: (text, record) => {
                return record && <div>
                    <div>{record.model}</div>
                    <div>{record.os_version}</div>
                </div>
            }
        },
        {
            title: `${translation["App version"] || TEXT["App version"]}`,
            align: "center",
            dataIndex: "app_version",
            key: "app_version",
            ...getColumnSearchProps("app_version"),
            render: (text, record, index) => record ? record.app_version : "-"
        },
        {
            title: 'Stream Format',
            align: "center",
            dataIndex: "stream_format",
            key: "stream_format",
            filters: [
                {
                    text: "default",
                    value: "default",
                },
                {
                    text: "m3u8",
                    value: "m3u8",
                },
                {
                    text: "ts",
                    value: "ts",
                },
            ],
            filterMultiple: false,
        },
        {
            title: `${translation["Online"] || TEXT["Online"]}`,
            align: "center",
            dataIndex: "online",
            sorter: true,
            render: (record, iteam, index) => {
                return iteam.online !== false ? (
                    <p>{translation["Online"] || TEXT["Online"]}</p>
                ) : iteam.last_online ? (
                    getMyDate(iteam.last_online)
                ) : (
                    "N/A"
                );
            },
        },
        {
            title: `${translation["Ip"] || TEXT["Ip"]}`,
            align: "center",
            dataIndex: "ip",
            key: "ip",
            ...getColumnSearchProps("ip"),
        },
        {
            title: ` ${translation["Created date"] || TEXT["Created date"]}`,
            align: "center",
            dataIndex: "createdAt",
            sorter: true,
            render: (text) => text ? getMyDate(text) : "N/A",
            filterDropdown: () => (
                <div
                    style={{
                        padding: 8,
                    }}
                >
                    <RangePicker
                        size="small"
                        onChange={(e, dateString) => setDate(dateString)}
                        renderExtraFooter={() => "extra footer"}
                    />
                </div>
            ),
            filterIcon: (filtered) => (
                <CalendarOutlined
                    style={{
                        color: JSON.parse(searchParams.get("between"))?.createdAt
                            ? "#1890ff"
                            : undefined,
                    }}
                />
            ),
        },
        {
            title: "",
            dataIndex: "action",
            align: "center",
            render: (text, record, index) => (
                <div onClick={(e) => e.stopPropagation()}>
                    <TableButtons
                        handleMenuClick={(e) => handleMenuClick(e, record)}
                        buttons={admin_permissions && admin_permissions["devices"] && [
                            {
                                key: "free_trial",
                                text: `${translation["Free trial"] || TEXT["Free trial"]}`,
                                icon: <ExpandAltOutlined />,
                                disabled: !admin_permissions["devices"]["Free trial"]
                            },
                            {
                                key: "activate",
                                text: `${translation["Activate"] || TEXT["Activate"]}`,
                                icon: <TagOutlined />,
                                disabled: !admin_permissions["devices"]["Activate"]
                            },
                            {
                                key: "deactivate",
                                text: `${translation["Deactivate"] || TEXT["Deactivate"]}`,
                                icon: ICONS.DEACTIVE,
                                disabled: !admin_permissions["devices"]["Deactivate"]
                            },
                            {
                                key: "delete",
                                text: `${translation["Delete"] || TEXT["Delete"]}`,
                                icon: <DeleteOutlined />,
                                disabled: !admin_permissions["devices"]["Delete"]
                            },
                            {
                                key: "stream_format",
                                text: "Edit Stream Format",
                                icon: <EditOutlined />,
                            },
                        ]}
                    />
                </div>
            )
        }
    ];

    const getDeviceManager = () => {
        setLoading(true);

        const query = {
            page: currentPage,
            limit,
            pagination: 1,
            sort: JSON.stringify(sort),
            search: {},
        };

        if (search.stream_format) {
            query.search["stream_format"] = search.stream_format[0];
        }

        if (search.user) {
            query.search["user"] = search.user[0];
        }

        if (search.code) {
            query.search["code"] = search.code[0];
        }

        if (search.mac) {
            query.search["mac"] = search.mac[0];
        }

        if (search.model) {
            query.search["model"] = search.model[0];
        }

        if (search.app_version) {
            query.search["app_version"] = search.app_version[0];
        }

        if (search.ip) {
            query.search["ip"] = search.ip[0];
        }

        if (query.search) {
            query.search = JSON.stringify(query.search);
        }

        if (search.status) {
            query.filter = {};

            if (search.status[0] === "payed") {
                query.filter["payed"] = true;
            }

            if (search.status[0] === "free_trial") {
                query["free_trial"] = true;
            }

            if (search.status[0] === "deactivated") {
                query["deactivated"] = true;
            }

            if (search.status[0] === "none") {
                query["none"] = true;
            }

            query.filter = JSON.stringify(query.filter);
        }

        let dataArray = [
            { date: date, betweenDate: "createdAt" },
            { date: expiredDate, betweenDate: "free_trial_expired" },
            { date: activationExpired, betweenDate: "activation_expired" },
        ];

        for (let i = 0; i < dataArray.length; i++) {
            if (dataArray[i].date && dataArray[i].date[0]) {
                let to = new Date(dataArray[i].date[1]);
                to.setDate(to.getDate() + 1);
                if (query.between) {
                    query.between = JSON.stringify({
                        ...JSON.parse(query?.between),
                        [dataArray[i].betweenDate]: {
                            from: new Date(dataArray[i].date[0]),
                            to: new Date(to),
                        },
                    });
                } else {
                    query.between = JSON.stringify({
                        [dataArray[i].betweenDate]: {
                            from: new Date(dataArray[i].date[0]),
                            to: new Date(to),
                        },
                    });
                }
            }
        }

        goToPosts(query);

        REQUESTS.DEVICE.GET(query, (data) => {
            setTotal(data.count);
            // setLimit(data.limit);
            if (data.rows.length === 0 && currentPage > 1) {
                setCurrentPage((current) => current - 1);
            } else {
                setCurrentPage(data.currentPage);
            }
            setLoading(false);

            setDeviceManager(data.rows);
        });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter.field) {
            setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
        }
        setSearch(filters);
        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
    };

    const closeDrawer = () => {
        setVisible(false);
    };

    const closeDActivationrawer = () => {
        setActivationVisible(false);
    };

    useEffect(() => {
        let timout = setTimeout(() => {
            getDeviceManager();
        }, 500);

        return () => {
            clearTimeout(timout);
        };
    }, [search, currentPage, limit, date, sort, expiredDate, activationExpired]);

    return (
        <div className={classes["devices_table"]}>
            <div className={classes["devices_table_head"]}>
                <p className={classes["devices_head_text"]}>
                    {translation["Devices"] || TEXT["Devices"]}
                </p>
            </div>

            <Table
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (e) => {
                            setUserInfon(record);
                            setVisible(true);
                        },
                    };
                }}
                loading={loading}
                rowKey="id"
                columns={columns}
                dataSource={deviceManager}
                rowClassName={classes["deviceManager_row"]}
                onChange={handleTableChange}
                pagination={{
                    position: ["bottomCenter"],
                    current: currentPage,
                    total: total,
                    pageSize: limit,
                    showSizeChanger: true,
                }}
                size="small"
                scroll={{ x: "max-content" }}
            />

            <DeviceManagerDrawer
                visible={visible}
                onClose={closeDrawer}
                userInfo={userInfo}
            />

            {
                admin_permissions && admin_permissions["devices"] &&
                admin_permissions["devices"]["Activate"] &&
                <ActivationDrawer
                    visible={activationVisible}
                    onClose={closeDActivationrawer}
                    current={editDevice}
                    getData={getDeviceManager}
                />
            }
            <StreamFormat
                device={editDevice}
                open={showStreamFormat}
                onClose={() => {
                    setShowStreamFormat(false);
                    setEditDevice(null);
                }}
                getDeviceManager={getDeviceManager}
            />
        </div>
    );
}

export default DeviceManager;
