import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Table, Input, Switch } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import LanguagesDraver from "./components/LanguagesDraver";
import { selectTranslation } from "../../features/Translation/TranslationSlice";
import { useNavigate, createSearchParams } from "react-router-dom";

import TEXT from "../../config/text";
import REQUESTS from "../../api/requests";

import classes from "./index.module.scss";
import { selectUser } from "../../features/DataUserProfile/DataUserProfileSlise";
function Languages() {
  const [total, setTotal] = useState(10);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [translationsData, settranslationsData] = useState(null);
  const [sort, setSort] = useState(["id", "ASC"]);
  const [search, setSearch] = useState({
    comment: "",
  });

  const translation = useSelector(selectTranslation);

  const { admin_permissions } = useSelector(selectUser);

  const navigate = useNavigate();
  const goToPosts = (params) =>
    navigate({
      search: `?${createSearchParams(params)}`,
    });

  useEffect(() => {
    goToPosts({ page: "languages" });
  }, []);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
      return (
        <Input
          allowClear
          autoFocus
          placeholder="Type text here"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
        />
      );
    },
    filterIcon: () => {
      return (
        <>
          <SearchOutlined />
        </>
      );
    },
  });

  const handleActiveClick = (e, item) => {
    const body = {
      id: item.id,
    };

    function callback() {
      getLanguages();
    }

    function errorCallback(err) { }

    REQUESTS.LENG.EDIT(body, callback, errorCallback);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "is_default",
      key: "is_default",
      align: "center",
      render: (text, record, index) => {
        if (currentPage === 1) {
          return index + 1;
        } else {
          return limit * (currentPage - 1) + index + 1;
        }
      },
    },

    {
      title: ` ${translation["Name"] || TEXT["Name"]}`,
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (record, text, index) => {
        return (
          <p>
            <img width={30} src={text.icon} alt="" /> {`${text?.name}`}
          </p>
        );
      },
      ...getColumnSearchProps(),
    },

    {
      title: ` ${translation["Active"] || TEXT["Active"]}`,
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      sorter: true,
      render: (text, record, index) => <div onClick={(e) => e.stopPropagation()}>
        <Switch
          onClick={(e) => handleActiveClick(e, record)}
          disabled={admin_permissions["settings"]["Update languages"] ? record.is_default : !admin_permissions["settings"]["Update languages"]}
          checked={record.is_active}
          style={{
            backgroundColor: record.is_active ? "#1890ff" : undefined,
          }}
        />
      </div >
    },
  ];

  const getLanguages = () => {
    setLoading(true);
    const query = {
      page: currentPage,
      limit,
      pagination: 1,
      sort: JSON.stringify(sort),
      search: {},
    };

    if (search.name) {
      query.search["name"] = search.name[0];
    }

    if (query.search) {
      query.search = JSON.stringify(query.search);
    }

    REQUESTS.LENG.GET(query, (data) => {
      setTotal(data.count);
      // setLimit(data.limit);
      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
      setLoading(false);

      setLanguages(data.rows);

    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    setSearch(filters);

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const closeDrawer = () => {
    setVisible(false);
  };

  useEffect(() => {
    let timout = setTimeout(() => {
      getLanguages();
    }, 500);
    return () => {
      clearTimeout(timout);
    };
  }, [search, currentPage, limit, sort]);

  return (
    <div className={classes["devices_table"]}>
      <div className={classes["Languages_table_head"]}>
        {/* <p className={classes["Languages_head_text"]}>
          <GlobalOutlined /> {translation["Languages"] || TEXT["Languages"]}
        </p> */}
      </div>

      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: (e) => {
              settranslationsData(record);
              if (admin_permissions?.["settings"]?.["Update languages"]) setVisible(true);
            },
          };
        }}
        rowClassName={admin_permissions?.["settings"]?.["Update languages"] ? classes["languages_row"] : ""}
        loading={loading}
        rowKey="id"
        columns={columns}
        dataSource={languages}
        onChange={handleTableChange}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
        scroll={{ x: "max-content" }}
        size="small"
      />

      <LanguagesDraver
        visible={visible}
        onClose={closeDrawer}
        translationsData={translationsData}
        translation={translation}
      />
    </div>
  );
}

export default Languages;
