import React, { useEffect, useState } from 'react';

import { Button, Drawer, Form, Tabs, Input } from 'antd';

import useResize from "../../../hooks/use-resize";

import REQUESTS from '../../../api/requests';

import ErrorMessage from '../../../components/ErrorMessage';

import styles from "../index.module.scss";

export default function AdminDrawer({ open, current, onClose, getData }) {
    const [form] = Form.useForm();

    const [message, setMessage] = useState({
        type: false,
        text: "",
    });
    const [loading, setLoading] = useState(false);

    const resize = useResize();

    const onFinish = (values) => {
        const { name, surname, email, password, comment } = values;
        setLoading(true);
        setMessage({
            type: false,
            text: "",
        });

        if (current) {
            const body = {
                id: current.id,
                password,
                email,
                comment: comment || "",
                surname,
                name,
            }

            REQUESTS.ADMINS.EDIT(body, (data) => {
                setLoading(false);
                getData();
                onClose();
            }, (error) => {
                setLoading(false);
                // notification.error({ message: error })
                setMessage({ type: false, text: error });
            })
        } else {
            const callback = () => {
                const body = {
                    email,
                    password,
                };

                if (name) body.name = name;
                if (surname) body.surname = surname;
                if (comment) body.comment = comment;

                REQUESTS.ADMINS.ADD(body, (data) => {
                    setLoading(false);
                    getData();
                    onClose();
                }, (error) => {
                    setLoading(false);
                    setMessage({ type: false, text: error });
                })
            };
            REQUESTS.ADMINS.CHECK_EMAIL({ email }, callback, (err) => {
                setLoading(false);
                setMessage({ type: false, text: err });
            })
        }
    }

    useEffect(() => {
        if (!open) {
            setLoading(false);
            form.resetFields();
            setMessage({
                type: false,
                text: "",
            })
        }
    }, [open]);

    useEffect(() => {
        if (current) {
            form.setFields([
                { name: "name", value: current.name, },
                { name: "surname", value: current.surname, },
                { name: "email", value: current.email, },
                { name: "comment", value: current.comment, },
            ]);
        }
    }, [current])

    return (
        <Drawer
            title={current ? "Edit Admin" : "Add Admin"}
            open={open}
            onClose={onClose}
            width={resize < 600 ? "90%" : 500}
        >
            <Form
                layout='vertical'
                form={form}
                onFinish={onFinish}
            >
                <Form.Item
                    name="name"
                    label="Name"
                // rules={[
                //     {
                //         required: true,
                //         message: 'Please input your name!',
                //     },
                // ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="surname"
                    label="Surname"
                // rules={[
                //     {
                //         required: true,
                //         message: 'Please input your surname!',
                //     },
                // ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        {
                            required: !current,
                            message: 'Please input your email!',
                        },
                        {
                            type: "email",
                            message: "Please input a valid email!",
                            validator: (_, value) => {
                                if (value) {
                                    if (value.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject("Please input a valid email!");
                                }
                                return Promise.resolve();
                            }
                        },
                    ]}
                >
                    <Input type='email' />
                </Form.Item>
                <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                        {
                            required: !current,
                            message: 'Please input your password!',
                        },
                        {
                            min: 8,
                            message: "Password must be at least 8 characters long!"
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="comment"
                    label="Comment"
                // rules={[
                //     {
                //         required: !current,
                //         message: 'Please input your comment!',
                //     },
                // ]}
                >
                    <Input.TextArea />
                </Form.Item>

                <ErrorMessage message={message} />

                <div className={styles['btn-container']}>
                    <Form.Item >
                        <Button type="primary" htmlType="submit" loading={loading}>Submit</Button>
                    </Form.Item>
                </div>
            </Form>
        </Drawer>
    )
}
