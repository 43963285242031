import { useEffect, useState } from "react";


export default function useResize() {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const rezise = () => {
            setWidth(window.innerWidth);
        }

        window.addEventListener("resize", rezise);


        return () => {
            window.removeEventListener("resize", rezise);
        };
    }, [width]);

    return width
}
