import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { Drawer, Table, Popover, Empty } from 'antd';

import { selectTranslation } from "../../../features/Translation/TranslationSlice";
import getMyDate from "../../../components/getMyDate";
import REQUESTS from "../../../api/requests";

import TEXT from '../../../config/text';

export default function PlaylistLogsDrawer({ openlogs, setOpenlogs, selectedPlaylist }) {

    const [data, setData] = useState([]);
    const [total, setTotal] = useState();
    const [limit, setLimit] = useState(10);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const translation = useSelector(selectTranslation);

    const getPlalistLogs = () => {
        const query = {
            page: currentPage,
            limit,
            pagination: 1,
            filter: JSON.stringify({ playlist_id: selectedPlaylist.id })
        };

        setLoading(true);

        const callback = (data) => {
            setLimit(data.limit);
            setTotal(data.count)
            setCurrentPage(data.currentPage)
            setData(data.rows)
            setLoading(false)
        };

        REQUESTS.DEVICE.GET_PLAYLIST_LOGS(query, callback);
    };

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            align: "center",
            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: `${translation["Title"] || TEXT["Title"]}`,
            dataIndex: "title",
            align: "center",
        },

        {
            title: `${translation["Date"] || TEXT["Date"]}`,
            dataIndex: "createdAt",
            align: "center",
            render: (record, text, index) => {
                return record ? getMyDate(record) : "N/A"
            },

        },
        {
            title: `${translation["Url"] || TEXT["Url"]}`,
            dataIndex: "url",
            align: "center",
            render: (record, text, index) => {
                if (record) {
                    const content = (
                        <div>
                            {record}
                        </div>
                    );
                    return (
                        <Popover content={content}>
                            <div style={{
                                whiteSpace: 'nowrap',
                                width: 150,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                                <a href={record} target={'_blank'}
                                    style={{ color: "#1890ff" }}
                                >{record}</a>
                            </div>
                        </Popover>
                    );
                }
            }
        },

        {
            title: `${translation["Message"] || TEXT["Message"]}`,
            dataIndex: "message",
            align: "center",
        },
    ];

    const handleTableChange = (pagination, filters, sorter) => {

        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
    };

    useEffect(() => {
        let timeout;

        if (selectedPlaylist && openlogs) {
            timeout = setTimeout(() => {
                getPlalistLogs();
            }, 500);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [selectedPlaylist, openlogs, currentPage, limit])

    useEffect(() => {
        function resize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener("resize", resize);

        return () => window.removeEventListener("resize", resize);

    }, [windowWidth])

    return (
        <Drawer
            title={translation["Logs"] || TEXT["Logs"]
            }
            placement="right"
            onClose={() => { setOpenlogs(false) }}
            open={openlogs}
            width={windowWidth > 870 ? 800 : "90vw"}
        >
            <Table
                loading={loading}
                rowKey="id"
                columns={columns}
                dataSource={data}
                onChange={handleTableChange}
                pagination={{
                    position: ["bottomCenter"],
                    current: currentPage,
                    total: total,
                    pageSize: limit,
                    showSizeChanger: true,
                }}
                scroll={{ x: "max-content" }}
                size="small"
                locale={{
                    emptyText: (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
                    ),
                }}
            />

        </Drawer>
    )
}
