import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectwidgets } from "../../../features/widgets/selector";

import TEXT from "../../../config/text";
import REQUESTS from "../../../api/requests";
import classes from "../style/userPackageListDrawer.module.scss";
import ErrorMessage from "../../../components/ErrorMessage";

import {
  Button,
  Form,
  Input,
  Drawer,
  Select,
  Switch,
  handleTableChange,
  Checkbox,
} from "antd";

const { Option } = Select;
const { TextArea } = Input;

const UserPackageListDrawer = ({
  onClose,
  visible,
  current,
  getData,
  translation,
  followAddElement,
  imaConfigs,
}) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [recommended, setRecommended] = useState(current?.recommended || false);
  const useScrollRef = useRef();
  const useParentRef = useRef();
  const [message, setMessage] = useState({
    type: false,
    text: "",
  });
  const [paypalEnabled, setPaypalEnabled] = useState(false);
  const [stripeEnabled, setStripeEnabled] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [checkedForWeb, setCheckedForWeb] = useState(null);
  const [checkedForReseller, setCheckedForReseller] = useState(null);
  const [checkedAds, setCheckedAds] = useState(false);
  const widgets = useSelector(selectwidgets);

  const onValuesChange = (changedValues, allValues) => {
    setMessage({
      type: false,
      text: "",
    });
  };

  function scrollIntoView() {
    setTimeout(() => {
      useScrollRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
    }, 200);
  }

  const onFinish = (values) => {
    setLoading(true);

    const {
      name,
      schedule,
      price,
      description,
      activation_needed,
      paypal_price_id,
      stripe_price_id,
    } = values;

    const bouquetIds = (values.bouquet_ids || "").split(",").map(Number);

    const body = {
      name,
      schedule,
      price,
      description,
      activation_needed: Number(activation_needed),
      bouquet_ids: bouquetIds,
      paypal_price_id,
      stripe_price_id,
      for_web: checkedForWeb,
      for_reseller: checkedForReseller,
      has_ads: imaConfigs && imaConfigs.is_enable ? checkedAds : false,
    };

    function callback() {
      if (!current) {
        followAddElement.setCurrentPage(1);
        followAddElement.setSearch("");
        followAddElement.setSort(["id", "DESC"]);
        followAddElement.setCurrentPage(1);
        followAddElement.setLimit(10);
        followAddElement.setTotal();
        followAddElement.setDate("");
      }

      setLoading(false);

      onClose();
      getData();
    }

    function errorCallback(err) {
      setLoading(false);
      setMessage({ type: false, text: err });
    }

    if (current) {
      body.id = current.id;
      REQUESTS.USER_PACKEGES.EDIT(body, callback, errorCallback);
    } else {
      REQUESTS.USER_PACKEGES.ADD(body, callback, errorCallback);
    }
  };

  const getPaymentSettings = () => {
    REQUESTS.PAYMENT_SETTINGS((data) => {
      setPaypalEnabled(data.paypal_enabled);
      setStripeEnabled(data.stripe_enabled);
    });
  };

  const onChange = (value) => {
    setSelectedValue(value);
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;

    switch (name) {
      case "for_web":
        setCheckedForWeb(checked);
        break;
      case "for_reseller":
        setCheckedForReseller(checked);
        break;
      case "has_ads":
        setCheckedAds(checked);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (visible) {
      scrollIntoView();
      getPaymentSettings();

    } else {
      setPaypalEnabled(false);
      setStripeEnabled(false);
      setLoading(false);
      setRecommended(false);
      onValuesChange();
      setRecommended(false);
      form.resetFields();
    }
  }, [visible]);

  useEffect(() => {
    if (current) {
      setCheckedAds(current.has_ads);
      setCheckedForWeb(current.for_web);
      setCheckedForReseller(current.for_reseller);
      setRecommended(current.recommended);

      form.setFields([
        {
          name: "name",
          value: current.name,
        },

        {
          name: "schedule",
          value: current.schedule,
        },

        {
          name: "price",
          value: current.price,
        },

        {
          name: "activation_needed",
          value: current.activation_needed,
        },

        {
          name: "description",
          value: current.description,
        },

        {
          name: "paypal_price_id",
          value: current.paypal_price_id,
        },

        {
          name: "stripe_price_id",
          value: current.stripe_price_id,
        },
        {
          name: "for_web",
          value: current.for_web,
        },
        {
          name: "for_reseller",
          value: current.for_reseller,
        },
        {
          name: "for_web/res",
          value: current.for_web && current.for_reseller,
        },
        {
          name: "bouquet_ids",
          value: JSON.parse(current?.bouquet_ids || "[]").join(","),
        },
      ]);
    } else {
      form.resetFields();
      setCheckedForWeb(false);
      setCheckedForReseller(false);
      setCheckedAds(false);
    }
  }, [current, visible]);

  return (
    <div ref={useParentRef}>
      <Drawer
        title={current ? "Edit Pricing" : "Add Pricing"}
        placement="right"
        onClose={onClose}
        open={visible}
      >
        <div ref={useScrollRef}></div>
        <Form
          form={form}
          name="profile"
          layout="vertical"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          initialValues={{
            schedule: current?.schedule,
          }}
        >
          <Form.Item
            label={translation["Name"] || TEXT["Name"]}
            name="name"
            rules={[
              {
                required: true,
                message: "Please input name",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={translation["Price"] || TEXT["Price"]}
            name="price"
            rules={[
              {
                required: true,
                message: "Please input price",
              },
            ]}
          >
            <Input type={"number"} />
          </Form.Item>
          <Form.Item
            label={translation["Schedule"] || TEXT["Schedule"]}
            name="schedule"
            rules={[
              {
                required: true,
                message: "Please input Schedule",
              },
            ]}
          >
            <Select
              className={classes["form_item_select"]}
              // defaultValue={current?.schedule}
              onChange={onChange}
            >
              <Option value="month">Monthly</Option>
              <Option value="year">Yearly</Option>
              <Option value="lifetime">Lifetime</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={
              translation["Credit needed"] || TEXT["Credit needed"]
            }
            name="activation_needed"
            rules={[
              {
                required: true,
                message: "Please input Credit!",
              },
            ]}
          >
            <Input type={"number"} min={0} />
          </Form.Item>
          <Form.Item
            label="Paypal price id"
            name="paypal_price_id"
            rules={[
              {
                required: paypalEnabled && selectedValue !== "lifetime",
                message: "Please input paypal price id!",
              },
            ]}
          >
            <Input disabled={selectedValue === "lifetime" ? true : false} />
          </Form.Item>
          <Form.Item
            label="Stripe price id"
            name="stripe_price_id"
            rules={[
              {
                required: stripeEnabled && selectedValue !== "lifetime",
                message: "Please input stripe price id!",
              },
            ]}
          >
            <Input disabled={selectedValue === "lifetime" ? true : false} />
          </Form.Item>
          <Form.Item
            label={translation["Description"] || TEXT["Description"]}
            name="description"
            rules={[
              {
                required: true,
                message: "Please input description!",
              },
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item
            rules={[{
              required: true,
              message: "Please input bouquet ids!",
            },
            {
              validator: (rule, value) => {
                if (value && !/^[0-9,]*$/.test(value)) {
                  return Promise.reject("Please input valid bouquet ids!");
                } else {
                  return Promise.resolve();
                }
              }
            }]}
            label={translation["Bouquet IDs"] || TEXT["Bouquet IDs"]}
            name="bouquet_ids"
          >
            <Input />
          </Form.Item>

          <ErrorMessage message={message} />

          <div className={classes["checkboxes-container"]}>
            <Checkbox
              name={"for_web"}
              checked={checkedForWeb}
              onChange={handleChange}
            >
              For Web
            </Checkbox>
            <Checkbox
              name={"for_reseller"}
              checked={checkedForReseller}
              onChange={handleChange}
              style={{ marginLeft: 0 }}
            >
              For Reseller
            </Checkbox>
            {widgets &&
              widgets.google_ima &&
              widgets.google_ima.enabled &&
              widgets.google_ima.enabled === "true" && (
                <Checkbox
                  name={"has_ads"}
                  checked={
                    imaConfigs && imaConfigs.is_enable ? checkedAds : false
                  }
                  onChange={handleChange}
                  style={{ marginLeft: 0 }}
                >
                  Ads Enabled
                </Checkbox>
              )}

            {imaConfigs && !imaConfigs.is_enable && checkedAds && (
              <ErrorMessage
                message={{
                  type: false,
                  text: "Turn on Google IMA from settings to use it",
                }}
              />
            )}
          </div>

          <div className={classes["save_button_div"]}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={classes["save_button"]}
                loading={loading}
              >
                {translation["Save"] || TEXT["Save"]}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Drawer>
    </div>
  );
};

export default UserPackageListDrawer;
