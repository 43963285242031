import { Table, Button, Empty } from "antd";

import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

export default function Series({ data, ids, setIDs }) {
    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
        },
        {
            title: 'Series Name',
            dataIndex: 'title',
            key: 'title',
            align: 'center',
        },
        {
            title: 'Category',
            dataIndex: "",
            key: '',
            align: 'center',
        },
        {
            title: 'Actions',
            dataIndex: "",
            key: '',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <div>
                        {
                            ids[record.id] ?
                                <Button size='small'
                                    onClick={(e) => onClick(record.id)}
                                    style={{ borderColor: '#03b2cb', color: '#03b2cb' }}
                                >
                                    <MinusOutlined />
                                </Button> :
                                <Button
                                    size='small'
                                    onClick={(e) => onClick(record.id)}
                                    style={{ borderColor: '#faab2f', color: '#faab2f' }}
                                >
                                    <PlusOutlined />
                                </Button>
                        }
                    </div>
                )
            }
        },
    ];

    const onClick = (id) => {
        setIDs((prev) => ({ ...prev, [id]: !prev[id] }))
    }

    return (
        <div>
            <Table
                columns={columns}
                dataSource={data}
                size='small'
                pagination={{
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                bordered
                locale={{
                    emptyText: (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
                    ),
                }}
            />
        </div>
    )
}
