import React, { useEffect, useState } from "react";
import { Area } from '@ant-design/plots';

import { DatePicker, Select, Space } from "antd";
import moment from 'moment';

import REQUESTS from "../../../api/requests";

import styles from "../styles/mostUsedDevices.module.scss";

import "../styles/chartStyle.scss";

const PickerWithType = ({ type, onChange, defaultValue }) => {
  if (type === "week") return <DatePicker picker={type} onChange={onChange} defaultValue={moment(new Date(), "MMM Do YY")} format={"YYYY-MM-DD"} />;
  return <DatePicker picker={type} onChange={onChange} defaultValue={moment(defaultValue, "YYYY-MM-DD")} format={"YYYY-MM-DD"} />;
};

const UsedDevicesProgres = () => {
  const [data, setData] = useState([]);

  const [selectedDate, setSelectedDate] = useState(`${(new Date()).getFullYear()}-01-01`);

  const [dateType, setDateType] = useState("year");

  const [platformType, setPlatformType] = useState("all");

  const config = {
    data,
    xField: dateType === "year" ? 'month' : "day",
    yField: 'count',
    seriesField: 'name',
  };

  const getChart = () => {
    const query = {
      data_type: dateType,
      date: selectedDate,
      type: platformType,
    };

    function collback(data) {
      if (Array.isArray(data)) {
        if (dateType === 'month' || dateType === 'week') {
          data = data.map((obj) => { return { ...obj, count: parseInt(obj.count), day: obj.day.toString() } })
        } else {
          data = data.map((obj) => { return { ...obj, count: parseInt(obj.count) } })
        }

        setData(data);

      } else {
        const clone = { ...data };

        const newData = [];

        for (let platform in clone) {
          for (let value of clone[platform]) {
            if (dateType === 'month' || dateType === 'week') {
              newData.push({ name: platform, count: parseInt(value.count), day: value.day.toString() })
            } else {
              newData.push({ name: platform, count: parseInt(value.count), month: value.month })
            }
          }
        }

        let _newData = newData.sort((a, b) => a.day - b.day)

        setData(_newData);
      }
    }

    function errorCollback(data) { }

    REQUESTS.STATISTICS.DEVICE_CHART(collback, errorCollback, query);
  };

  useEffect(() => {
    getChart();
  }, [selectedDate, dateType, platformType]);
  return (
    <div className="most-used-devices-BarChart">
      <div className={styles["most-used-devices-chart-container"]}>
        <div className={styles["title-datepicker-container"]}>
          <span className={styles["title"]}>DEVICE</span>
          <Space>
            <Select value={dateType} onChange={setDateType}>
              <Select.Option value="week">Week</Select.Option>
              <Select.Option value="month">Month</Select.Option>
              <Select.Option value="year">Year</Select.Option>
            </Select>
            <PickerWithType
              type={dateType}
              onChange={(value) => {
                const year = (new Date(value._d)).getFullYear();
                const month = (new Date(value._d)).getMonth();
                const day = (new Date(value._d)).getDate()

                if (dateType === 'year') {
                  setSelectedDate(`${year}-01-01`)
                } else if (dateType === 'month') {
                  setSelectedDate(`${year}-${month < 9 ? '0' + (month + 1) : (month + 1)}-01`)
                } else {
                  setSelectedDate(`${year}-${month < 9 ? '0' + (month + 1) : (month + 1)}-${day > 9 ? day : '0' + day}`)
                }
              }}
              defaultValue={selectedDate}
            />
            <Select
              style={{
                width: 120,
              }}
              defaultValue={platformType}
              onChange={setPlatformType}
              options={[
                {
                  value: 'platform',
                  label: 'Platform',
                },
                {
                  value: 'all',
                  label: 'All',
                },
              ]}
            />
          </Space>
        </div>
        <Area {...config} style={{ height: 350 }} />
      </div>
    </div>
  );
};

export default UsedDevicesProgres;
