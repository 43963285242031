import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Input, notification } from "antd";

import { selectTranslation } from "../../../features/Translation/TranslationSlice";
import { selectwidgets } from "../../../features/widgets/selector";

import TEXT from "../../../config/text";
import ErrorMessage from "../../../components/ErrorMessage";
import REQUESTS from "../../../api/requests";
import { selectUser } from "../../../features/DataUserProfile/DataUserProfileSlise";

export default function GoogleReCaptcha() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    type: false,
    text: "",
  });
  const [disabled, setDisabled] = useState(true);
  const [captchaKeys, setCaptchaKeys] = useState(null);

  const translation = useSelector(selectTranslation);
  const widgets = useSelector(selectwidgets);

  const [form] = Form.useForm();

  const { admin_permissions } = useSelector(selectUser);

  const getCaptchaKeys = () => {
    REQUESTS.CAPTCHA.GET((data) => {
      setCaptchaKeys(data);
    });
  };

  const onFinish = (values) => {
    setLoading(true);
    function callback(data) {
      setLoading(false);
      notification.open({
        message: `${translation["Google reCAPTCHA"] || TEXT["Google reCAPTCHA"]
          }`,
        description: data,
        duration: 5,
      });
      getCaptchaKeys();
    }
    function errorCallback(err) {
      setLoading(false);

      setMessage({ type: false, text: err });
    }
    if (!disabled) {
      REQUESTS.CAPTCHA.PUT(values, callback, errorCallback);
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    if (
      (allValues.secret_key && allValues.site_key) ||
      (!allValues.secret_key && !allValues.site_key)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  useEffect(() => {
    getCaptchaKeys();
  }, []);

  useEffect(() => {
    if (captchaKeys) {
      form.setFields([
        {
          name: "site_key",
          value: captchaKeys.site_key,
        },
        {
          name: "secret_key",
          value: captchaKeys.secret_key,
        },
      ]);
    }
  }, [captchaKeys]);

  return (
    <div>
      <h3 className="google-reCaptcha-title"> Google reCAPTCHA</h3>
      <Form
        form={form}
        name="profile"
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <div style={{ width: 250 }}>
          <Form.Item
            label={translation["SITE KEY"] || TEXT["SITE KEY"]}
            name="site_key"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={translation["SECRET KEY"] || TEXT["SECRET KEY"]}
            name="secret_key"
          >
            <Input />
          </Form.Item>

          <ErrorMessage message={message} />
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: 250 }}
              disabled={admin_permissions && admin_permissions["settings"] && admin_permissions["settings"]["Update google recaptcha"] ? disabled : true}
            >
              {translation["Save"] || TEXT["Save"]}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
