import { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { Drawer, Checkbox, notification } from "antd";

import useResize from "../../../hooks/use-resize";

import REQUESTS from "../../../api/requests";

import styles from "../index.module.scss";

export default function Permissions({ open, onClose, current }) {
    const [permissions, setPermissions] = useState({});

    const profile = useSelector((state) => {
        return state.userInfo.user;
    });

    const resize = useResize();

    const permissionsKeys = Object.keys(permissions);
    const midPoint = Math.ceil(permissionsKeys.length / 2);
    const firstHalf = permissionsKeys.slice(0, midPoint);
    const secondHalf = permissionsKeys.slice(midPoint);

    const groupPermissions = () => {
        let permissions = {};

        current.admin_permissions.forEach((item) => {
            if (!permissions[item.group]) {
                permissions[item.group] = {};
            };

            permissions[item.group][item.name] = item.enabled;
        });

        setPermissions(permissions)
    }

    const handleCheckboxChange = (item) => {
        const [name, group] = item;
        const updatedPermissions = { ...permissions };

        setPermissions(
            {
                ...updatedPermissions,
                [group]: { ...updatedPermissions[group], [name]: !permissions[group][name] }
            }
        );

    };

    const renderCheckboxesList = (list, group) => {
        let profilePermisions = profile.admin_permissions;

        return Object.entries(list).map(([key, value], index) => {

            let disableItem = profilePermisions[group][key]
            return <Checkbox
                key={index}
                checked={value}
                className={styles['checkbox']}
                onChange={(e) => {
                    handleCheckboxChange([key, group]);
                    update([key, group])
                }}
                disabled={!disableItem}
            >
                {key}
            </Checkbox>
        })
    }

    const update = (item) => {
        const [name, group] = item;

        const body = {
            admin_id: current.id,
            group,
            name,
            enabled: !permissions[group][name],
        }

        const callback = (data) => {
            notification.success({
                message: "Success",
                description: "Permission updated successfully!"

            })
        }

        const errorCallback = (error) => {
            notification.error({
                message: "Error",
                description: error,
            })
        }

        REQUESTS.ADMINS.PERMISSIONS(body, callback, errorCallback)
    }

    useEffect(() => {
        if (current) groupPermissions();
    }, [current]);

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title="Permissions"
            width={resize > 600 ? 600 : "100%"}
        >
            <div className={styles['permissions-list']}>
                <div>
                    {firstHalf.map((group, index) => {
                        return <div key={index}>
                            <div className={styles['checkboxes-list']}>
                                <h3>{group.charAt(0).toUpperCase() + group.slice(1)}</h3>
                                {renderCheckboxesList(permissions[group], group)}
                            </div>
                        </div>
                    })
                    }
                </div>
                <div>
                    {secondHalf.map((group, index) => {
                        return <div key={index}>
                            <div className={styles['checkboxes-list']}>
                                <h3>{group.charAt(0).toUpperCase() + group.slice(1)}</h3>
                                {renderCheckboxesList(permissions[group], group)}
                            </div>
                        </div>
                    })
                    }
                </div>
            </div>
        </Drawer>
    )
}
