import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export default function getColumnSearchProps(type) {
  return ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
      return (
        <Input
          allowClear
          autoFocus
          placeholder="Type text here"
          value={selectedKeys[0]}
          onChange={(e) => {

            let value = e.target.value;

            if (type == "email") value = value.trim();

            setSelectedKeys(value ? [value] : []);
            confirm({ closeDropdown: false });
          }}
        />
      );
    },
    filterIcon: () => {
      return (
        <>
          <SearchOutlined />
        </>
      );
    },
  })
}

